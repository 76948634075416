import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import TableNumberInput from "./minicomponents/TableNumberInput";

function Table() {
  const [showRows, setShowRows] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleRow = (index) => {
    setShowRows((prevShowRows) => {
      const newShowRows = [...prevShowRows];
      newShowRows[index] = !newShowRows[index];
      return newShowRows;
    });
  };

  return (
    <Container
      className="border border-2 my-4 bg-white px-sm-5 py-4"
      style={{ borderRadius: "15px" }}
    >
      <h4 className="text-center mb-4" style={{ fontFamily: "Poppins" }}>
        Interest Vs Principal
      </h4>

      <hr />

      <Row>
        <div className="col">
          <div className="card border-0">
            <div className="card-body pt-2 pb-0 px-0">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-hover text-center table-layout--fixed">
                  <thead className="bg-primary text-white">
                    <tr>
                      <th className="px-0" scope="col">
                        Year
                      </th>
                      <th className="px-0 text-nowrap" scope="col">
                        Opening Balance
                      </th>
                      <th
                        className="px-0 text-nowrap bg-success text-white"
                        scope="col"
                      >
                        Principal
                      </th>
                      <th
                        className="px-0 text-nowrap bg-primary text-white"
                        scope="col"
                      >
                        Interest
                      </th>
                      <th
                        className="px-0 text-nowrap bg-danger text-white"
                        scope="col"
                      >
                        EMI
                      </th>
                      <th className="px-0 text-nowrap" scope="col">
                        Outstanding
                      </th>
                      <th className="px-0 bg-warning text-nowrap" scope="col">
                        Part Payment
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {showRows.map((show, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <button
                              className="btn btn-light border border-skyblue px-2 py-0 me-2"
                              onClick={() => toggleRow(index)}
                            >
                              {show ? "-" : "+"}
                            </button>
                            2022
                          </td>

                          <td className="px-0">₹ 400000</td>
                          <td className="px-0">₹ 34903</td>
                          <td className="px-0">₹ 28091</td>
                          <td className="px-0">₹ 62994</td>
                          <td className="px-0">₹ 365097</td>
                          <td className="px-0">₹ 0</td>
                        </tr>

                        {show && (
                          <>
                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Jan
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Feb
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Mar
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Apr
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                May
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Jun
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Jul
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Aug
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Sep
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Oct
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Nov
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>

                            <tr>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                Dec
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 400
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 34
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 28
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 62
                              </td>
                              <td
                                className="px-0"
                                style={{
                                  background: "rgba(145, 198, 253, 0.2)",
                                }}
                              >
                                ₹ 365
                              </td>

                              <TableNumberInput />
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Table;
