import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoanComparePie1 from "../charts/LoanComparePie1";
import HomeBarChart from "../charts/HomeBarChart";

function Table() {
  const loansArr = [
    {
      name: "Loan1",
      totalLoanAmount: "Rs. 4444",
      outstanding: "44455",
      monthlyemi: "444",
      interestRate: "5.25%",
      remainingTenure: "7 Years 3 Months",
    },
    {
      name: "Loan2",
      totalLoanAmount: "Rs. 4444",
      outstanding: "44455",
      monthlyemi: "444",
      interestRate: "5.25%",
      remainingTenure: "7 Years 3 Months",
    },
    {
      name: "Loan3",
      totalLoanAmount: "Rs. 4444",
      outstanding: "44455",
      monthlyemi: "444",
      interestRate: "5.25%",
      remainingTenure: "7 Years 3 Months",
    },
    {
      name: "Loan4",
      totalLoanAmount: "Rs. 4444",
      outstanding: "44455",
      monthlyemi: "444",
      interestRate: "5.25%",
      remainingTenure: "7 Years 3 Months",
    },
  ];

  const [showRows, setShowRows] = useState([false, false, false, false]);

  const toggleRow = (index) => {
    setShowRows((prevShowRows) => {
      const newShowRows = [...prevShowRows];
      newShowRows[index] = !newShowRows[index];
      return newShowRows;
    });
  };

  return (
    <Container
      className="border border-2 my-4 bg-white px-sm-5 py-4"
      style={{ borderRadius: "15px" }}
    >
      <h4 className="text-center mb-4" style={{ fontFamily: "Poppins" }}>
        Your Loans
      </h4>

      <hr />

      <Row>
        <div className="col">
          <div className="card border-0">
            <div className="card-body pt-2 pb-0 px-0">
              <div style={{ overflowX: "auto" }}>
                <table className="table text-center table-layout--fixed">
                  <thead>
                    <tr>
                      <th className="px-0" scope="col"></th>

                      <th
                        className="px-0 text-white"
                        scope="col"
                        style={{ backgroundColor: "rgba(17, 85, 247, 0.6)" }}
                      >
                        Total Loan Amount
                      </th>

                      <th
                        className="px-0 text-nowrap text-white"
                        scope="col"
                        style={{ backgroundColor: "rgba(17, 85, 247, 0.6)" }}
                      >
                        Outstanding
                      </th>

                      <th
                        className="px-0 text-nowrap text-white"
                        scope="col"
                        style={{ backgroundColor: "rgba(17, 85, 247, 0.6)" }}
                      >
                        Monthly EMI
                      </th>

                      <th
                        className="px-0 text-nowrap text-white"
                        scope="col"
                        style={{ backgroundColor: "rgba(17, 85, 247, 0.6)" }}
                      >
                        Interest Rate
                      </th>

                      <th
                        className="px-0 text-nowrap text-white"
                        scope="col"
                        style={{ backgroundColor: "rgba(17, 85, 247, 0.6)" }}
                      >
                        Remaining Tenure
                      </th>

                      <th className="px-0 text-nowrap" scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {showRows.map((show, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            className="px-0 fs-6 fw-bold"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].name}
                          </td>

                          <td
                            className="px-0"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].totalLoanAmount}
                          </td>

                          <td
                            className="px-0"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].outstanding}
                          </td>

                          <td
                            className="px-0"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].monthlyemi}
                          </td>

                          <td
                            className="px-0"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].interestRate}
                          </td>

                          <td
                            className="px-0"
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            {loansArr[index].remainingTenure}
                          </td>

                          <td
                            style={{
                              background: "rgba(145, 198, 253, 0.2)",
                            }}
                          >
                            <button
                              className="btn btn-light border border-skyblue px-2 py-0 me-2"
                              onClick={() => toggleRow(index)}
                            >
                              {show ? "-" : "+"}
                            </button>
                          </td>
                        </tr>

                        {show && (
                          <>
                            <tr>
                              <td className="px-0" colSpan={7}>
                                <Container>
                                  <Row className="mb-5 mt-4 mx-0">
                                    <Col>
                                      <LoanComparePie1 />
                                    </Col>

                                    <div className="col g-2">
                                      <div className="row my-1">
                                        <div
                                          className="card text-white"
                                          style={{ backgroundColor: "#152733" }}
                                        >
                                          <div className="row g-0">
                                            <div className="col-md-4">
                                              <p className="card-text fw-bold text-white text-center ms-4 me-2 mt-2">
                                                Total Loan Amount
                                              </p>
                                            </div>
                                            <div className="col-md-8">
                                              <div className="card-body py-2">
                                                <h5 className="card-title text-center">
                                                  $4536
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row my-1">
                                        <div
                                          className="card text-white"
                                          style={{ backgroundColor: "#152733" }}
                                        >
                                          <div className="row g-0">
                                            <div className="col-md-4">
                                              <p className="card-text fw-bold text-white text-center ms-4 me-2 mt-2">
                                                Total Interest
                                              </p>
                                            </div>
                                            <div className="col-md-8">
                                              <div className="card-body py-2">
                                                <h5 className="card-title text-center">
                                                  $1985
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row my-1">
                                        <div
                                          className="card text-dark"
                                          style={{
                                            backgroundColor: "rgb(255, 193, 7)",
                                          }}
                                        >
                                          <div className="row g-0">
                                            <div className="col-md-4">
                                              <p className="card-text fw-bold text-dark text-center ms-4 me-2 mt-2">
                                                Total Payment
                                              </p>
                                            </div>
                                            <div className="col-md-8">
                                              <div className="card-body py-2">
                                                <h5 className="card-title text-center">
                                                  $6521
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row my-1 mt-3">
                                        <HomeBarChart />
                                      </div>
                                    </div>
                                  </Row>

                                  {/* TABLE */}
                                  <Row>
                                    <div className="col">
                                      <div className="card border-0">
                                        <div className="card-body pt-2 pb-0 px-0">
                                          <div style={{ overflowX: "auto" }}>
                                            <table className="table table-hover text-center table-layout--fixed">
                                              <thead className="bg-primary text-white">
                                                <tr>
                                                  <th
                                                    className="px-0"
                                                    scope="col"
                                                  >
                                                    Year
                                                  </th>
                                                  <th
                                                    className="px-0 text-nowrap"
                                                    scope="col"
                                                  >
                                                    Opening Balance
                                                  </th>
                                                  <th
                                                    className="px-0 text-nowrap bg-success text-white"
                                                    scope="col"
                                                  >
                                                    Principal
                                                  </th>
                                                  <th
                                                    className="px-0 text-nowrap bg-primary text-white"
                                                    scope="col"
                                                  >
                                                    Interest
                                                  </th>
                                                  <th
                                                    className="px-0 text-nowrap bg-danger text-white"
                                                    scope="col"
                                                  >
                                                    EMI
                                                  </th>
                                                  <th
                                                    className="px-0 text-nowrap"
                                                    scope="col"
                                                  >
                                                    Outstanding
                                                  </th>
                                                  <th
                                                    className="px-0 bg-warning text-nowrap"
                                                    scope="col"
                                                  >
                                                    Part Payment
                                                  </th>
                                                </tr>
                                              </thead>

                                              <tbody>
                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>

                                                <tr>
                                                  <td>2022</td>

                                                  <td className="px-0">
                                                    ₹ 400000
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 34903
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 28091
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 62994
                                                  </td>
                                                  <td className="px-0">
                                                    ₹ 365097
                                                  </td>
                                                  <td className="px-0">₹ 0</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                  {/* END TABLE */}
                                </Container>
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Table;
