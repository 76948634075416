import React, { useContext, useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import LoanComparePie from "../charts/LoanComparePie";
import HomeBarChart from "../charts/HomeBarChart";
import Api from "../services/Api";
import { AppContext } from "../AppContext";

export default function LoanCompareTable() {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/

	const { loansToCompare, user } = useContext(AppContext);

	const [loansDataToCompare, setLoansDataToCompare] = useState([]);

	const [showRows, setShowRows] = useState(null);

	const toggleRow = (id) => {
		setShowRows((prevShowRows) => (prevShowRows === id ? null : id));
	};

	// use effect
	useEffect(() => {
		let loans = loansToCompare;
		if (loans) {
			loans = loans.map((loan, index) => ({
				...loan,
				id: index + 1,
			}));

			setLoansDataToCompare(loans);
		}
		// else {
		// 	// get loans data from api
		// 	Api("post", "users/getLoans").then((res) => {
		// 		if (res.success) {
		// 			// set interest & principle plus interest
		// 			res.data.forEach((loanData) => {
		// 				// set
		// 				loanData.totalInterest =
		// 					loanData.EMI * loanData.Tenure - loanData.amount;
		// 				loanData.totalPrinciplePlusInterest =
		// 					Number(loanData.amount) + Number(loanData.totalInterest);

		// 				// set symbol
		// 				loanData.amount_symbol = "$";
		// 				if (loanData.amount_currency === "inr")
		// 					loanData.amount_symbol = "₹";
		// 				if (loanData.amount_currency === "eur")
		// 					loanData.amount_symbol = "€";
		// 			});

		// 			// set loans data
		// 			setLoansData(res.data);
		// 			setLoansDataToCompare(res.data);
		// 		}
		// 	});
		// }
	}, [loansToCompare]);

	/* ----------------------------------------------------------------------------------------
	renders
	-------------------------------------------------------------------------------------------*/

	const renderAmortizationButton = (id) => (
		<>
			{showRows !== id && (
				<Button
					variant="outline-darkblue"
					className="px-3"
					onClick={() => toggleRow(id)}
				>
					Amortization
				</Button>
			)}

			{showRows === id && (
				<Button
					variant="darkblue"
					className="px-3"
					onClick={() => toggleRow(id)}
				>
					Amortization
				</Button>
			)}
		</>
	);

	const renderAmortizationSection = (loanData) => (
		<>
			{showRows === loanData.id && (
				<tr key={loanData.id}>
					<td className="px-0" colSpan={5}>
						<Container>
							<Row>
								<h4 className="mt-4">Amortization - Loan {loanData.id}</h4>
							</Row>

							<Row className="mb-5 mt-4 mx-0">
								<Col>
									<LoanComparePie
										payment={loanData.totalPrinciplePlusInterest.toFixed(0)}
										interest={loanData.totalInterest.toFixed(0)}
										symbol={loanData.amount_symbol}
									/>
								</Col>

								<div className="col g-2">
									<div className="row my-1">
										<div
											className="card text-white"
											style={{
												backgroundColor: "#152733",
											}}
										>
											<div className="row g-0">
												<div className="col-md-4">
													<p className="card-text fw-bold text-white text-center ms-4 me-2 mt-2">
														Total Loan Amount
													</p>
												</div>
												<div className="col-md-8">
													<div className="card-body py-2">
														<h5 className="card-title text-center">
															$
															{loanData.amount
																? loanData.amount.toLocaleString()
																: loanData.loanAmount.amount.toLocaleString()}
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-1">
										<div
											className="card text-white"
											style={{
												backgroundColor: "#152733",
											}}
										>
											<div className="row g-0">
												<div className="col-md-4">
													<p className="card-text fw-bold text-white text-center ms-4 me-2 mt-2">
														Total Interest
													</p>
												</div>
												<div className="col-md-8">
													<div className="card-body py-2">
														<h5 className="card-title text-center">
															${loanData.totalInterest.toLocaleString()}
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-1">
										<div
											className="card text-dark"
											style={{
												backgroundColor: "rgb(255, 193, 7)",
											}}
										>
											<div className="row g-0">
												<div className="col-md-4">
													<p className="card-text fw-bold text-dark text-center ms-4 me-2 mt-2">
														Total Payment
													</p>
												</div>
												<div className="col-md-8">
													<div className="card-body py-2">
														<h5 className="card-title text-center">
															$
															{loanData.totalPrinciplePlusInterest.toLocaleString()}
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-1 mt-3">
										<HomeBarChart />
									</div>
								</div>
							</Row>
						</Container>
					</td>
				</tr>
			)}
		</>
	);

	/* ----------------------------------------------------------------------------------------
	return
	-------------------------------------------------------------------------------------------*/
	return (
		<Container
			className="border border-2 my-4 bg-white px-5 py-4"
			style={{ borderRadius: "15px" }}
		>
			<Row>
				<div className="col">
					<div className="card border-0">
						<div className="card-body pt-2 px-0">
							<div className="container-fluid px-0">
								<div className="row">
									<div className="col">
										<div className="table-responsive">
											<table className="table table-hover text-center">
												{/* title */}
												<thead>
													<tr>
														<th className="w-25"></th>
														{loansDataToCompare.map(({ id }) => (
															<th key={id} className="table-primary">
																Loan {id}
															</th>
														))}
													</tr>
												</thead>
												{/* contents */}
												<tbody>
													{/* amount */}
													<tr>
														<th className="text-start w-25">Loan Amount</th>
														{loansDataToCompare.map(
															({ id, amount, amount_symbol }, i) => (
																<td key={id}>
																	{amount_symbol}{" "}
																	{amount
																		? amount.toLocaleString()
																		: loansDataToCompare[
																				i
																		  ].loanAmount.amount.toLocaleString()}
																</td>
															)
														)}
													</tr>

													{/* ROI */}
													<tr>
														<th className="text-start w-25">ROI</th>
														{loansDataToCompare.map(({ id, Interest }, i) => (
															<td key={id} className="fw-bold">
																{Interest || loansDataToCompare[i].ROI}%
															</td>
														))}
													</tr>

													{/* Tenure */}
													<tr>
														<th className="text-start w-25">Tenure</th>
														{loansDataToCompare.map(({ id, Tenure }, i) => (
															<td key={id} className="fw-bold">
																{Tenure
																	? Math.round(Tenure / 12)
																	: loansDataToCompare[i].tenure.value}{" "}
																Yr.
															</td>
														))}
													</tr>

													{/* EMI */}
													<tr>
														<th className="text-start w-25">EMI</th>
														{loansDataToCompare.map(
															({ id, EMI, amount_symbol }) => (
																<td key={id} className="fw-bold">
																	{amount_symbol} {EMI.toLocaleString()}
																</td>
															)
														)}
													</tr>

													{/* Total Interest */}
													<tr>
														<th className="text-start w-25">Total Interest</th>
														{loansDataToCompare.map(
															({ id, totalInterest, amount_symbol }) => (
																<td key={id} className="fw-bold">
																	{amount_symbol}{" "}
																	{totalInterest.toLocaleString()}
																</td>
															)
														)}
													</tr>

													{/* Total principle + interest */}
													<tr>
														<th className="text-start w-25">Total Payment</th>
														{loansDataToCompare.map(
															({
																id,
																totalPrinciplePlusInterest,
																amount_symbol,
															}) => (
																<td key={id} className="fw-bold">
																	{amount_symbol}{" "}
																	{totalPrinciplePlusInterest.toLocaleString()}
																</td>
															)
														)}
													</tr>

													{/* Resto de las filas de datos */}

													{/* charts */}
													<tr>
														<th className="text-start w-25">Charts</th>
														{loansDataToCompare.map(
															({
																id,
																totalPrinciplePlusInterest,
																totalInterest,
															}) => (
																<td key={id} style={{ maxWidth: "10rem" }}>
																	{/* Componente de gráfico específico para cada préstamo */}
																	<LoanComparePie
																		id={id}
																		payment={totalPrinciplePlusInterest.toFixed(
																			0
																		)}
																		interest={totalInterest.toFixed(0)}
																	/>
																</td>
															)
														)}
													</tr>

													{/* button */}
													<tr>
														<th className="text-start w-25"></th>
														{loansDataToCompare.map(({ id }) => (
															<td key={id}>{renderAmortizationButton(id)}</td>
														))}
													</tr>

													{loansDataToCompare.map((loanData) =>
														renderAmortizationSection(loanData)
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Row>
		</Container>
	);
}
