import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo from "./logo.png";
import facebookIcon from "./media/facebook-icon.png";
import twitterIcon from "./media/twitter-icon.png";
import googleIcon from "./media/google-icon.png";

export default function Footer() {
  return (
    <footer className="bg-bluishblack text-light mt-5">
      <Container className="pt-4 pb-1">
        <Row className="py-3">
          {/* FINVEST LOGO CLOUMN */}
          <Col md={4}>
            {/* FINVEST LOGO */}
            <img
              src={logo}
              className="d-inline-block align-top"
              style={{ height: "5rem" }}
              alt="Finvest kit logo"
            />
            {/* END FINVEST LOGO */}

            <p style={{ fontSize: "small" }} className="my-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the.
            </p>

            {/* SOCIAL MEDIA BUTTONS */}
            <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
              <button
                type="button"
                className="btn btn-bluishblack btn-floating me-1"
              >
                <img src={facebookIcon} alt="Facebook icon"></img>
              </button>

              <button
                type="button"
                className="btn btn-bluishblack btn-floating mx-1"
              >
                <img src={twitterIcon} alt="Twitter icon"></img>
              </button>

              <button
                type="button"
                className="btn btn-bluishblack btn-floating mx-1"
              >
                <img src={googleIcon} alt="Google icon"></img>
              </button>
            </div>
            {/* END SOCIAL MEDIA BUTTONS */}
          </Col>
          {/* END FINVEST LOGO CLOUMN */}

          {/* LINKS COLUMNS */}
          <Col md={8}>
            <Row>
              <Col md={4}>
                <h5 style={{ marginBottom: "2.2rem" }}>Product</h5>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Download
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Payments
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Loan Services
                  </a>
                </p>
              </Col>

              <Col md={4}>
                <h5 style={{ marginBottom: "2.2rem" }}>Company</h5>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    About Us
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Contact Us
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Blog
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Carrers
                  </a>
                </p>
              </Col>

              <Col md={4}>
                <h5 style={{ marginBottom: "2.2rem" }}>Services</h5>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Home Loan
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Auto Loan
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Personal/Consumer Loan
                  </a>
                </p>

                <p className="my-4">
                  <a href="#" className="text-decoration-none text-bluishgrey">
                    Education Loan
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
          {/* END LINKS COLUMNS */}
        </Row>

        <Row>
          <hr />
          <p className="text-center">
            © {new Date().getFullYear()} FINVEST kit. All rights reserved.
          </p>
        </Row>
      </Container>
    </footer>
  );
}
