import { useState } from "react";

export default function RightAddonYrMo(props) {
	const [type, setType] = useState('yr');
	const [value, setValue] = useState(0);


	const handleReturn = (newValue = null, newType = null) => {
		let tempType = type;
		let tempValue = value;

		if(newValue)
		{
			setValue(newValue);
			tempValue = newValue;
		}

		if(newType)
		{
			setType(newType);
			tempType = newType;
		}

		if(props.onChange)
		props.onChange({
			type: tempType ?? type, 
			value: tempValue ?? value,
		});
	}

  return (
    <div className="input-group my-2">
      <input
        type="number"
        className="form-control border-bluishgrey"
        id={props.id}
        name={props.name}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        aria-label={props.arialabel}
				onChange={e => handleReturn(e.currentTarget.value)}
      />

      <input
        className="btn-check"
        type="radio"
        name={props.radiosName}
        id={props.radioYrId}
				onChange={e => handleReturn(null, 'yr')}
        defaultChecked
      />

      <label
        className="input-group-text btn btn-light border-bluishgrey"
        htmlFor={props.radioYrId}
      >
        {props.addontext1}
      </label>

      <input
        className="btn-check"
        type="radio"
        name={props.radiosName}
				onChange={e => handleReturn(null, 'mo')}
        id={props.radioMoId}
      />

      <label
        className="input-group-text btn btn-light border-bluishgrey"
        htmlFor={props.radioMoId}
      >
        {props.addontext2}
      </label>
    </div>
  );
}

/*
<>
  <RightAddonYrMo
    id={"tenure1"}
    name={"tenure1"}
    min={"0"}
    max={"10000"}
    placeholder={"0"}
    addontext1={"Yr"}
    addontext2={"Mo"}
    arialabel={"tenure"}
    radiosName={"tenureRadios1"}
    radioYrId={"tenureRadioYr1"}
    radioMoId={"tenureRadioMo1"}
  />
</>;
 */