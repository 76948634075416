import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import HomePieChart from "./charts/HomePieChart";
import HomeBarChart from "./charts/HomeBarChart";

import HomeTable from "./HomeTable";
import { useContext } from "react";

import { AppContext } from "./AppContext";

function Charts1() {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/
	// context
	const { loanResult } = useContext(AppContext);

	return (
		<>
			<Container
				className="border border-2 my-4 bg-white p-5"
				style={{ borderRadius: "15px" }}
			>
				<div className="row justify-content-center">
					<div className="col-sm-12 col-lg-6 me-5">
						<HomePieChart />
					</div>
					<div className="col-sm-12 col-lg-5 g-2 me-md-0 me-4 align-self-center">
						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												$ {Number(loanResult.loanAmount.amount).toFixed(2)}
											</p>
										</div>
										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												Loan Amount
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												% {Number(loanResult.ROI).toFixed(1)}
											</p>
										</div>

										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												ROI
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												{Number(loanResult.tenure.value)}{" "}
												{loanResult.tenure.type}
											</p>
										</div>

										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												Tenure
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												$ {Number(loanResult.EMI).toFixed(2)}
											</p>
										</div>

										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												EMI
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												${" "}
												{Number(loanResult.totalPrinciplePlusInterest).toFixed(
													2
												)}
											</p>
										</div>

										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												Total Owned
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row my-2">
							<div className="card bg-base text-dark border border-neonblue">
								<div className="card-body" style={{ padding: "0.70rem 0" }}>
									<div className="row">
										<div className="col-md-8">
											<p
												className="card-text fw-bold text-dark text-center text-md-start"
												style={{ margin: "0.15rem 0" }}
											>
												$ {Number(loanResult.totalInterest).toFixed(2)}
											</p>
										</div>

										<div className="col-md-4">
											<h6 className="card-title text-center text-md-end my-1">
												Total Interest
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <div className="col-sm-12 col-lg col-md-5 hidde-sm">
            <p className="mx-3 my-3 lh-lg">
              <ul>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dolop.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipr sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dolor sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dol sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dolor sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dolor sit.
                </li>
                <li
                  className="my-3"
                  style={{ opacity: "0.85", color: "#152733" }}
                >
                  Lorem ipsum dolor sit.
                </li>
              </ul>
            </p>
          </div> */}
				</div>
			</Container>

			{/* HOME BAR CHART */}
			<Container
				className="border border-2 my-4 bg-white p-5"
				style={{ borderRadius: "15px" }}
			>
				<Row>
					<HomeBarChart />
				</Row>
			</Container>
			{/* END HOME BAR CHART */}

			{/* TABLE INTEREST VS PRINCIPAL */}
			<HomeTable />
			{/* END TABLE INTEREST VS PRINCIPAL */}
		</>
	);
}

export default Charts1;
