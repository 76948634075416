import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.min.css";
import "./css/style.css";

// Importación del Context Provider
import { AppProvider } from "./AppContext";

// Importación de tus componentes de página
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";

// TOSO: Revisar por qué no detecta los valores al usar variables de entorno, probar con dotenv
const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

function App() {
	const app = initializeApp(firebaseConfig);
	const auth = getAuth(app);
	return (
		<AppProvider>
			<Router>
				<Routes>
					<Route path="/" element={<HomePage auth={auth} />} />
					<Route path="/login" element={<LoginPage auth={auth} />} />
					<Route path="/signup" element={<SignupPage auth={auth} />} />
					<Route path="/dashboard" element={<DashboardPage auth={auth} />} />
					<Route path="*" element={<ErrorPage />} />
				</Routes>
			</Router>
		</AppProvider>
	);
}

export default App;
