import { Bar } from "react-chartjs-2";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

let loan1 = [
	7252, 7010, 6835, 6620, 6400, 6190, 5956, 5632, 5312, 4909, 4410, 4022, 3600,
];

let loan2 = [
	6152, 5910, 6735, 5520, 6300, 5090, 4856, 4532, 4112, 3709, 3310, 3022, 2600,
];

let loan3 = [
	7052, 6810, 6635, 6020, 5600, 5490, 5056, 4832, 4512, 4109, 3810, 3322, 3000,
];

let loan4 = [
	4252, 4010, 3835, 3620, 3400, 3190, 2956, 2632, 2312, 1909, 1410, 1022, 600,
];

let years = [
	"2023",
	"2025",
	"2027",
	"2029",
	"2031",
	"2033",
	"2035",
	"2037",
	"2039",
	"2041",
	"2043",
];

let misoptions = {
	responsive: true,
	animation: true,
	plugins: { legend: { display: true } },
	scales: {
		y: { min: 0, max: 8000 },
		x: { ticks: { color: "rgba(0,220,195)" } },
	},
};

let midata = {
	labels: years,
	datasets: [
		{
			label: "Loan1",
			data: loan1,
			backgroundColor: "rgba(145, 198, 253,0.8)",
		},

		{
			label: "Loan2",
			data: loan2,
			backgroundColor: "rgba(17, 85, 247,0.7)",
		},

		{
			label: "Loan3",
			data: loan3,
			backgroundColor: "rgba(0,220,195,0.8)",
		},

		{
			label: "Loan4",
			data: loan4,
			backgroundColor: "rgba(14, 68, 198,0.8)",
		},
	],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
	return <Bar data={midata} options={misoptions} />;
}
