import { useContext, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import RightAddonNumberInput from "../minicomponents/RightAddonNumberInput";
import RightAddonYrMo from "../minicomponents/RightAddonYrMo";
import LoanAmountInput from "../minicomponents/LoanAmountInput";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

const MultiStepForm3 = () => {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/
	// navigate & context
	const navigate = useNavigate();
	const {
		user,
		setLoanResultData,
		setSaveLoan,
		storeLoans,
		getLoansToCompareQuantity,
	} = useContext(AppContext);

	// loan amount
	const [loanAmount, setLoanAmount] = useState({
		currency: null,
		amount: null,
	});

	// interest rate
	const [interestRate, setInterestRate] = useState(0);

	// tenure
	const [tenure, setTenure] = useState({
		type: null,
		value: null,
	});

	// calculate
	const [isCalculated, setIsCalculated] = useState(false);

	// compare
	const [isComparing, setIsComparing] = useState(false);

	// save
	const [isSaving, setIsSaving] = useState(false);

	/* ----------------------------------------------------------------------------------------
	Handles
	-------------------------------------------------------------------------------------------*/

	// check calc
	const handleCheckCalc = () => {
		// ---------------------- start calc
		// calc tenure
		let tenureInMonths = tenure.value;
		if (tenure.type === "yr") tenureInMonths = tenure.value * 12;

		// Convertir la tasa de interés a porcentaje
		let interestRatePercentPerMonth = interestRate / 12 / 100;

		// Calcular el factor de interés
		let factorInteres = Math.pow(
			1 + interestRatePercentPerMonth,
			tenureInMonths
		);

		// Calcular la EMI utilizando la fórmula
		let emi =
			(loanAmount.amount * interestRatePercentPerMonth * factorInteres) /
			(factorInteres - 1);
		// fix emi to 2 decimal places
		emi = parseFloat(emi.toFixed(2));

		// Calc interest rate
		let totalInterest = emi * tenureInMonths - Number(loanAmount.amount);

		// Calc interest total
		const totalPrincipalPlusInterest =
			Number(loanAmount.amount) + totalInterest;

		// save to context
		setLoanResultData({
			// loan type
			loan_type: 2,
			// loan data
			ROI: interestRate,
			tenure: tenure,
			EMI: emi,
			loanAmount: loanAmount,
			// calcs
			totalInterest: totalInterest,
			totalPrinciplePlusInterest: totalPrincipalPlusInterest,
		});

		// set is calculated
		setIsCalculated(true);
	};

	// handle restart
	const handleRestartLoan = () => {
		// set is calculated
		setIsCalculated(false);
	};

	// add to compare
	const handleAddToCompare = () => {
		if (user) {
			setIsComparing(true);
			storeLoans();
			setIsComparing(false);
			handleRestartLoan();
		} else if (!user && getLoansToCompareQuantity() < 2) {
			setIsComparing(true);
			storeLoans();
			handleRestartLoan();
			setIsComparing(false);
		} else {
			navigate("/login");
		}
	};

	// const saving loan
	const handleSaveLoan = () => {
		if (user) {
			setIsSaving(true);
			setSaveLoan().then(() => {
				setIsSaving(false);
				handleRestartLoan();
			});
		} else {
			storeLoans(true);
			handleRestartLoan();
			navigate("/login");
		}
	};

	/* ----------------------------------------------------------------------------------------
	others
	-------------------------------------------------------------------------------------------*/

	const renderStepContent = () => {
		switch (1) {
			case 1:
				return (
					<Container className="p-relative">
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Loan Amount</legend>

								<LoanAmountInput
									id={"loanAmount1"}
									name={"loanAmount1"}
									min={"0"}
									max={"100000000"}
									placeholder={"5,000,000"}
									arialabel={"loan amount"}
									onChange={(e) => setLoanAmount(e)}
								/>
							</fieldset>

							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Interest Rate</legend>

								<RightAddonNumberInput
									id={"interestRate1"}
									name={"interestRate1"}
									min={"0"}
									max={"20"}
									placeholder={"8.00"}
									addontext={"%"}
									arialabel={"interest rate"}
									onChange={(e) => setInterestRate(e.currentTarget.value)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}

						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Tenure</legend>

								<RightAddonYrMo
									id={"tenure1"}
									name={"tenure1"}
									min={"0"}
									max={"10000"}
									placeholder={"0"}
									addontext1={"Yr"}
									addontext2={"Mo"}
									arialabel={"tenure"}
									radiosName={"tenureRadios1"}
									radioYrId={"tenureRadioYr1"}
									radioMoId={"tenureRadioMo1"}
									onChange={(e) => setTenure(e)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			default:
				return null;
		}
	};

	return (
		<Container className="p-relative">
			{/* FORM INPUTS */}
			<Row className="align-items-center" style={{ minHeight: "360px" }}>
				{/* ARROW PREVIOUS */}
				<div className="col-1 p-0"></div>
				{/* END ARROW PREVIOUS */}

				{/* FORM STEPS */}
				<div className="col-9 mx-sm-3">
					<Form>{renderStepContent()}</Form>
				</div>
				{/* END FORM STEPS */}

				{/* ARROW NEXT */}
				<div className="col-1 p-0"></div>
				{/* END ARROW NEXT */}
			</Row>
			{/* END FORM INPUTS */}

			{/* BUTTON CALCULATE */}
			<Row className="ms-sm-3 me-sm-5 g-3">
				{/* calculate */}
				<div className="d-grid">
					<Button
						variant="darkblue"
						className="py-2 px-4"
						type="submit"
						disabled={!loanAmount || !tenure || !interestRate}
						onClick={handleCheckCalc}
					>
						Calculate
					</Button>
				</div>
			</Row>
			{/* EMD BUTTON CALCULATE */}

			{/* Btn restart & add to compare */}
			{isCalculated && (
				<div className="loan-calculated">
					<p>
						Loan calculations are complete! The results are ready for your
						review.
					</p>
					<div className="row w-100 row-center">
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleSaveLoan}
							>
								{!isSaving && <span>Save Loan</span>}
								{isSaving && <span>Please wait...</span>}
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleRestartLoan}
							>
								Reset Loan
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100 text-nowrap"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleAddToCompare}
							>
								{!isComparing && <span>Add to Compare</span>}
								{isComparing && <span>Please wait...</span>}
							</Button>
						</div>
					</div>
				</div>
			)}
			{/* END Btn restart & add to compare */}
		</Container>
	);
};

export default MultiStepForm3;
