import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

let loan1 = [
  7564, 7021, 6580, 6103, 5621, 5231, 4806, 4365, 4123, 3654, 3245, 2834, 2104,
];
let loan2 = [
  6324, 5894, 5487, 5045, 4789, 4458, 4000, 3854, 3521, 3002, 2456, 1954, 1458,
];
let loan3 = [
  6854, 6654, 6214, 5745, 5214, 4658, 4178, 3658, 3254, 2756, 2465, 1874, 1358,
];
let loan4 = [
  4587, 4365, 3964, 3658, 3458, 3158, 2865, 2658, 2458, 2136, 1854, 1524, 1125,
];

let years = [
  "2023",
  "2025",
  "2027",
  "2029",
  "2031",
  "2033",
  "2035",
  "2037",
  "2039",
  "2041",
  "2043",
  "2045",
  "2047",
];

let midata = {
  labels: years,
  datasets: [
    {
      label: "Loan1",
      data: loan1,
      tension: 0.5,
      fill: false,
      borderColor: "rgb(255, 0, 0)",
      backgroundColor: "rgb(255, 0, 0)",
      pointRadius: 5,
      pointBorderColor: "rgb(255, 0, 0)",
      pointBackgroundColor: "rgb(255, 0, 0)",
    },

    {
      label: "Loan2",
      data: loan2,
      tension: 0.5,
      fill: false,
      borderColor: "rgb(155, 99, 132)",
      backgroundColor: "rgb(155, 99, 132)",
      pointRadius: 5,
      pointBorderColor: "rgb(155, 99, 132)",
      pointBackgroundColor: "rgb(155, 99, 132)",
    },

    {
      label: "Loan3",
      data: loan3,
      tension: 0.5,
      fill: false,
      borderColor: "rgb(0, 240, 0)",
      backgroundColor: "rgb(0, 240, 0)",
      pointRadius: 5,
      pointBorderColor: "rgb(0, 240, 0)",
      pointBackgroundColor: "rgb(0, 240, 0)",
    },

    {
      label: "Loan4",
      data: loan4,
      tension: 0.5,
      fill: false,
      borderColor: "rgb(255, 99, 23)",
      backgroundColor: "rgb(255, 99, 23)",
      pointRadius: 5,
      pointBorderColor: "rgb(255, 99, 23)",
      pointBackgroundColor: "rgb(255, 99, 23)",
    },
  ],
};

let misoptions = {
  scales: {
    y: { min: 0 },
    x: {
      ticks: { color: "blue" },
    },
  },
  plugins: {
    legend: { display: true },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return <Line data={midata} options={misoptions} />;
}
