/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { StorageGetData } from "./Storage";

/**
 * conector maestro
 * @param {*} method
 * @param {*} endpoint
 * @param {*} dataf
 */
const Api = (method, endpoint, data) => {
	// variables
	  // const base_url = "http://localhost:8000/api/";
	const base_url = "https://loan.globexpace.com/api/";

	// headers
	let axiosHeaders = {
		"Content-Type": "application/json",
	};

	// set user token
	let userToken = StorageGetData("AppProvider_user");
	if (userToken) axiosHeaders["Authorization"] = userToken;

	// return
	return new Promise((resolve, reject) => {
		axios({
			method: method.toLowerCase(),
			url: base_url + endpoint,
			data: data,
			headers: axiosHeaders,
			withCredentials: false,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				console.error("API ERROR:", error.response.data);
			});
	});
};

export default Api;
