import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

let rates = [
  500564528, 350564528, 460564528, 220564528, 159564528, 111564528, 368564528,
  560564528, 478564528, 359564528, 164564528, 541564528, 265564528,
]; //la data
let years = [
  "2023",
  "2025",
  "2027",
  "2029",
  "2031",
  "2033",
  "2035",
  "2037",
  "2039",
  "2041",
  "2043",
  "2045",
  "2047",
];

let misoptions = {
  responsive: true,
  animation: true,
  plugins: { legend: { display: false } },
  scales: {
    y: { min: 0, max: 600000000 },
    x: { ticks: { color: "rgba(0,220,195)" } },
  },
};

let midata = {
  labels: years,
  datasets: [
    {
      label: "Rate",
      data: rates,
      backgroundColor: "rgba(0,220,195,0.5)",
    },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return <Bar data={midata} options={misoptions} />;
}
