import { useContext, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import arrow from "../media/arrow-icon.svg";

import RightAddonNumberInput from "../minicomponents/RightAddonNumberInput";
import RightAddonYrMo from "../minicomponents/RightAddonYrMo";
import LoanAmountInput from "../minicomponents/LoanAmountInput";

import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

const MultiStepForm2 = () => {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/
	// navigate & context
	const navigate = useNavigate();
	const {
		user,
		setLoanResultData,
		setLoanReset,
		setSaveLoan,
		storeLoans,
		getLoansToCompareQuantity,
	} = useContext(AppContext);

	// steps
	const [step, setStep] = useState(1);
	// const [finalStep, setFinalStep] = useState(1);

	// type vehicle
	const [typeVehicle, setTypeVehicle] = useState(null);

	// state vehicle
	const [stateVehicle, setStateVehicle] = useState(null);

	// fuel vehicle
	const [fuelVehicle, setFuelVehicle] = useState(null);

	// for buttons
	const [disableBtnPrev, setDisableBtnPrev] = useState(true);
	const [disableBtnNext, setDisableBtnNext] = useState(true);

	// loan amount
	const [loanAmount, setLoanAmount] = useState({
		currency: null,
		amount: null,
	});

	// interest rate
	const [interestRate, setInterestRate] = useState(0);

	// tenure
	const [tenure, setTenure] = useState({
		type: null,
		value: null,
	});

	// calculate
	const [isCalculated, setIsCalculated] = useState(false);

	// compare
	const [isComparing, setIsComparing] = useState(false);

	// save
	const [isSaving, setIsSaving] = useState(false);

	/* ----------------------------------------------------------------------------------------
	Handles
	-------------------------------------------------------------------------------------------*/

	// type vehicle ----
	const handleTypeVehicle = (ev) => {
		setTypeVehicle(ev.currentTarget.value);
		setDisableBtnNext(false);
	};

	// state
	const handleStateVehicle = (ev) => {
		setStateVehicle(ev.currentTarget.value);
		setDisableBtnNext(false);
	};

	// fuel
	const handleFuelVehicle = (ev) => {
		setFuelVehicle(ev.currentTarget.value);
		setDisableBtnNext(false);
	};

	// btn previus
	const handlePrevious = () => {
		// condiciones
		switch (step) {
			case 3:
			case 4:
			case 5:
			case 6:
				setStep(step - 1);
				setDisableBtnPrev(false);
				setDisableBtnNext(false);
				break;

			default:
				setStep(step - 1);
				setDisableBtnPrev(true);
				setDisableBtnNext(false);
				break;
		}
		setLoanResultDataObj();
	};

	// btn next
	const handleNext = () => {
		// condiciones
		switch (step) {
			case 1:
				setStep(step + 1);
				setDisableBtnPrev(false);
				break;

			default:
				setStep(step + 1);
				setDisableBtnNext(true);
				break;
		}
		setLoanResultDataObj();
	};

	// restart
	const handleStartOver = () => {
		setStep(1);

		// reset loan context
		setLoanReset();
	};

	const setLoanResultDataObj = () => {
		setLoanResultData({
			// loan type
			loan_type: 1, // loan auto|vehicle
			// more info
			vehicle_type: typeVehicle,
			vehicle_state: stateVehicle,
			vehicle_fuel: fuelVehicle,
			// loan data
			ROI: interestRate,
			tenure: tenure,
			loanAmount: loanAmount,
		});
	};

	// check calc
	const handleCheckCalc = () => {
		// ---------------------- start calc
		// calc tenure
		let tenureInMonths = tenure.value;
		if (tenure.type === "yr") tenureInMonths = tenure.value * 12;

		// Convertir la tasa de interés a porcentaje
		let interestRatePercentPerMonth = interestRate / 12 / 100;

		// Calcular el factor de interés
		let factorInteres = Math.pow(
			1 + interestRatePercentPerMonth,
			tenureInMonths
		);

		// Calcular la EMI utilizando la fórmula
		let emi =
			(loanAmount.amount * interestRatePercentPerMonth * factorInteres) /
			(factorInteres - 1);
		// fix emi to 2 decimal places
		emi = parseFloat(emi.toFixed(2));

		// Calc interest rate
		let totalInterest = emi * tenureInMonths - Number(loanAmount.amount);

		// Calc interest total
		const totalPrincipalPlusInterest =
			Number(loanAmount.amount) + totalInterest;

		// save to context
		setLoanResultData({
			// loan type
			loan_type: 1, // loan auto|vehicle
			// more info
			vehicle_type: typeVehicle,
			vehicle_state: stateVehicle,
			vehicle_fuel: fuelVehicle,
			// loan data
			ROI: interestRate,
			tenure: tenure,
			EMI: emi,
			loanAmount: loanAmount,
			// calcs
			totalInterest: totalInterest,
			totalPrinciplePlusInterest: totalPrincipalPlusInterest,
		});

		// set is calculated
		setIsCalculated(true);
	};

	// handle restart
	const handleRestartLoan = () => {
		// set is calculated
		setIsCalculated(false);
		handleStartOver();
	};

	// add to compare
	const handleAddToCompare = () => {
		if (user) {
			setIsComparing(true);
			storeLoans();
			setIsComparing(false);
			handleRestartLoan();
		} else if (!user && getLoansToCompareQuantity() < 2) {
			setIsComparing(true);
			storeLoans();
			handleRestartLoan();
			setIsComparing(false);
		} else {
			navigate("/login");
		}
	};

	// const saving loan
	const handleSaveLoan = () => {
		if (user) {
			setIsSaving(true);
			setSaveLoan().then(() => {
				setIsSaving(false);
				handleRestartLoan();
			});
		} else {
			storeLoans(true);
			handleRestartLoan();
			navigate("/login");
		}
	};

	/* ----------------------------------------------------------------------------------------
	others
	-------------------------------------------------------------------------------------------*/

	/*
  const formAnimateNext = () => {
    let loanAmount2fieldset = document.getElementById("loanAmount2fieldset");
    let interestRate2fieldset = document.getElementById(
      "interestRate2fieldset"
    );
    let tenure2row = document.getElementById("tenure2row");
    let loanAmount2row = document.getElementById("loanAmount2row");
    let tenure2fieldset = document.getElementById("tenure2fieldset");
    let emi2fieldset = document.getElementById("emi2fieldset");

    let interestRate2 = document.getElementById("interestRate2");
    let tenure2 = document.getElementById("tenure2");
    let emi2 = document.getElementById("emi2");
    let loanAmount2legend = document.getElementById("loanAmount2legend");

    let interestRateAnim = () => {
      interestRate2fieldset.classList.replace("display-none", "col-sm-6");

      interestRate2.classList.add("form-input--outline");
    };

    let emiAnim = () => {
      tenure2.classList.remove("form-input--outline");

      emi2.classList.add("form-input--outline");

      emi2fieldset.classList.replace("display-none", "col-sm-6");
    };

    if (finalStep === 1) {
      loanAmount2legend.classList.replace("fs-2", "fs-6");
      loanAmount2legend.classList.remove("mb-4");
      loanAmount2fieldset.classList.replace("col-sm-12", "col-sm-6");

      setTimeout(interestRateAnim, 405);

      setFinalStep(2);
    } else if (finalStep === 2) {
      let stepAnim = () => {
        tenure2row.classList.remove("display-none");

        loanAmount2row.classList.replace("opacity-null", "opacity-full");
      };

      interestRate2.classList.remove("form-input--outline");

      tenure2.classList.add("form-input--outline");

      loanAmount2row.classList.replace("opacity-full", "opacity-null");

      setTimeout(stepAnim, 205);

      setFinalStep(3);
    } else if (finalStep === 3) {
      tenure2fieldset.classList.replace("col-sm-12", "col-sm-6");

      setTimeout(emiAnim, 405);

      setFinalStep(4);
    }
  };

  const formAnimatePrevious = () => {
    let loanAmount2fieldset = document.getElementById("loanAmount2fieldset");
    let interestRate2fieldset = document.getElementById(
      "interestRate2fieldset"
    );
    let tenure2row = document.getElementById("tenure2row");
    let containerAutoCase4 = document.getElementById("containerAutoCase4");
    let tenure2fieldset = document.getElementById("tenure2fieldset");
    let emi2fieldset = document.getElementById("emi2fieldset");

    let interestRate2 = document.getElementById("interestRate2");
    let tenure2 = document.getElementById("tenure2");
    let emi2 = document.getElementById("emi2");
    let loanAmount2legend = document.getElementById("loanAmount2legend");

    let interestRateAnim = () => {
      interestRate2fieldset.classList.replace("col-sm-6", "display-none");
    };

    let emiAnim = () => {
      emi2fieldset.classList.replace("col-sm-6", "display-none");

      emi2.classList.remove("form-input--outline");
    };

    if (finalStep === 4) {
      emiAnim();

      tenure2.classList.add("form-input--outline");

      tenure2fieldset.classList.replace("col-sm-6", "col-sm-12");

      setFinalStep(3);
    } else if (finalStep === 3) {
      let stepAnim = () => {
        tenure2row.classList.add("display-none");

        tenure2.classList.remove("form-input--outline");

        interestRate2.classList.add("form-input--outline");

        containerAutoCase4.classList.replace("opacity-null", "opacity-full");

        setFinalStep(2);
      };

      containerAutoCase4.classList.replace("opacity-full", "opacity-null");

      setTimeout(stepAnim, 205);
    } else if (finalStep === 2) {
      interestRateAnim();

      loanAmount2fieldset.classList.replace("col-sm-6", "col-sm-12");

      loanAmount2legend.classList.replace("fs-6", "fs-2");
      loanAmount2legend.classList.add("mb-4");

      interestRate2.classList.add("form-input--outline");

      setFinalStep(1);
    }
  };

	*/

	const renderStepContent = () => {
		switch (step) {
			case 1:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm mb-3 py-0">
								<legend className="fs-2 fw-bold border-bottom form-legend--line-height mb-4">
									Type of Vehicle
								</legend>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="car"
										value="Car"
										onChange={(e) => handleTypeVehicle(e)}
										defaultChecked={typeVehicle === "car"}
									/>
									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="car"
									>
										Car
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="motorCycle"
										value="Motor Cycle"
										onChange={(e) => handleTypeVehicle(e)}
										defaultChecked={typeVehicle === "motor-cicle"}
									/>

									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="motorCycle"
									>
										Motor Cycle
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="commercial"
										value="Commercial"
										onChange={(e) => handleTypeVehicle(e)}
										defaultChecked={typeVehicle === "commercial"}
									/>
									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="commercial"
									>
										Commercial
									</label>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 2:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm mb-3 py-0">
								<legend className="fs-2 fw-bold border-bottom form-legend--line-height mb-4">
									State of Vehicle
								</legend>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="carNew"
										value="New"
										onChange={(e) => handleStateVehicle(e)}
										defaultChecked={stateVehicle === "new"}
									/>

									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="carNew"
									>
										New
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="used"
										value="Used"
										onChange={(e) => handleStateVehicle(e)}
										defaultChecked={stateVehicle === "used"}
									/>

									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="used"
									>
										Used
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="lease"
										value="Lease"
										onChange={(e) => handleStateVehicle(e)}
										defaultChecked={stateVehicle === "lease"}
									/>

									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="lease"
									>
										Lease
									</label>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 3:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm mb-3 py-0">
								<legend className="fs-2 fw-bold border-bottom form-legend--line-height mb-4">
									Fuel Type
								</legend>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="electric"
										value="Electric"
										onChange={(e) => handleFuelVehicle(e)}
										defaultChecked={fuelVehicle === "electric"}
									/>
									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="electric"
									>
										Electric
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="petrol"
										value="Petrol"
										onChange={(e) => handleFuelVehicle(e)}
										defaultChecked={fuelVehicle === "petrol"}
									/>
									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="petrol"
									>
										Petrol
									</label>
								</div>

								<div className="form-check">
									<input
										className="form-check-input fs-5"
										type="radio"
										name="gridRadios"
										id="diesel"
										value="Diesel"
										onChange={(e) => handleFuelVehicle(e)}
										defaultChecked={fuelVehicle === "diesel"}
									/>
									<label
										className="form-check-label font-poppins fs-5"
										htmlFor="diesel"
									>
										Diesel
									</label>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 4:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Loan Amount</legend>

								<LoanAmountInput
									id={"loanAmount1"}
									name={"loanAmount1"}
									min={"0"}
									max={"100000000"}
									placeholder={"5,000,000"}
									arialabel={"loan amount"}
									onChange={(e) => setLoanAmount(e)}
								/>
							</fieldset>

							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Interest Rate</legend>

								<RightAddonNumberInput
									id={"interestRate1"}
									name={"interestRate1"}
									min={"0"}
									max={"20"}
									placeholder={"8.00"}
									addontext={"%"}
									arialabel={"interest rate"}
									onChange={(e) => setInterestRate(e.currentTarget.value)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}

						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Tenure</legend>

								<RightAddonYrMo
									id={"tenure1"}
									name={"tenure1"}
									min={"0"}
									max={"10000"}
									placeholder={"0"}
									addontext1={"Yr"}
									addontext2={"Mo"}
									arialabel={"tenure"}
									radiosName={"tenureRadios1"}
									radioYrId={"tenureRadioYr1"}
									radioMoId={"tenureRadioMo1"}
									onChange={(e) => setTenure(e)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			default:
				return null;
		}
	};

	return (
		<Container className="p-relative">
			{/* FORM INPUTS */}
			<Row className="align-items-center" style={{ minHeight: "360px" }}>
				{/* ARROW PREVIOUS */}
				<div className="col-1 p-0">
					<Button
						variant="darkblue"
						className="rounded-circle"
						onClick={handlePrevious}
						disabled={disableBtnPrev ? "disabled" : ""}
					>
						<img
							src={arrow}
							alt="next-arrow"
							className="p-0 mb-1 inverse"
						></img>
					</Button>
				</div>
				{/* END ARROW PREVIOUS */}

				{/* FORM STEPS */}
				<div className="col-9 mx-sm-3">
					<Form>{renderStepContent()}</Form>
				</div>
				{/* END FORM STEPS */}

				{/* ARROW NEXT */}
				<div className="col-1 p-0 text-right">
					<Button
						variant="darkblue"
						className="rounded-circle"
						onClick={handleNext}
						disabled={disableBtnNext ? "disabled" : ""}
					>
						<img src={arrow} alt="next-arrow" className="p-0 mb-1"></img>
					</Button>
				</div>
				{/* END ARROW NEXT */}
			</Row>
			{/* END FORM INPUTS */}

			{/* BUTTONS CALCULATE/START OVER/ADD TO COMPARE */}
			<Row className="ms-sm-3 me-sm-5 g-3">
				{/* calculate */}
				<div className="col">
					<div className="d-grid">
						{step < 4 && (
							<Button
								variant="darkblue"
								className="py-2 px-4"
								type="submit"
								disabled
							>
								Calculate
							</Button>
						)}
						{step === 4 && (
							<Button
								variant="darkblue"
								className="py-2 px-4"
								type="submit"
								disabled={!loanAmount || !tenure || !interestRate}
								onClick={handleCheckCalc}
							>
								Calculate
							</Button>
						)}
					</div>
				</div>

				{/* start over */}
				{step > 1 && (
					<div className="col">
						<div className="d-grid">
							<Button
								variant="darkblue"
								className="py-2 px-4 text-nowrap"
								type="submit"
								onClick={handleStartOver}
							>
								Start Over
							</Button>
						</div>
					</div>
				)}
			</Row>
			{/* EMD BUTTONS CALCULATE/START OVER/ADD TO COMPARE */}

			{/* to calculated */}
			{isCalculated && (
				<div className="loan-calculated">
					<p>
						Loan calculations are complete! The results are ready for your
						review.
					</p>
					<div className="row w-100 row-center">
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleSaveLoan}
							>
								{!isSaving && <span>Save Loan</span>}
								{isSaving && <span>Please wait...</span>}
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleRestartLoan}
							>
								Reset Loan
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100 text-nowrap"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleAddToCompare}
							>
								{!isComparing && <span>Add to Compare</span>}
								{isComparing && <span>Please wait...</span>}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Container>
	);
};

export default MultiStepForm2;
