import { Pie } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

let options = { responsive: true, maintainAspectRatio: false };

let data = {
  labels: ["Loans", "Insurance", "Mutual Funds"],
  datasets: [
    {
      label: "%",
      data: [30, 22, 48],
      backgroundColor: ["#1155F7", "#91C6FD", "#2096F4"],
      borderColor: ["#1155F7", "#91C6FD", "#2096F4"],
      borderWidth: 1,
    },
  ],
};

export default function PortfolioPieChart() {
  return <Pie data={data} options={options} />;
}
