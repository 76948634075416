import { useState } from "react";

export default function LoanAmountInput(props) {

	const [currency, setCurrency] = useState('inr');
	const [amount, setAmount] = useState(0);


	// handle
	const handleReturn = (evCurrency = null, evAmount = null) => {
		let tempCurrency = currency;
		let tempAmount = amount;

		if(evCurrency)
		{
			setCurrency(evCurrency.currentTarget.value);
			tempCurrency = evCurrency.currentTarget.value;
		}

		if(evAmount)
		{
			setAmount(evAmount.currentTarget.value);
			tempAmount = evAmount.currentTarget.value;
		}

		if(props.onChange)
		props.onChange({
			currency: tempCurrency ?? currency,
			amount: tempAmount ?? amount,
		});

	}

  return (
    <div className="input-group my-2">
      <select className="form-select input-group-text border-bluishgrey ps-3 pe-4" aria-label="select coin" onChange={e => handleReturn(e)}>
        <option value="inr">INR</option>
        <option value="usd">USD</option>
        <option value="eur">EUR</option>
      </select>

      <input
        type="number"
        className="form-control border-bluishgrey w-50"
        id={props.id}
        name={props.name}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        aria-label={props.arialabel}
				onChange={e => handleReturn(null, e)}
        required
      />
    </div>
  );
}

/*
	<>
		<LoanAmountInput
			id={""}
			name={""}
			min={"0"}
			max={""}
			placeholder={""}
			arialabel={""}
		/>;
	</>; 
*/
