import { Pie } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function LoanComparePie(props) {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/	

	let options = { responsive: true, maintainAspectRatio: false };

	let data = {
		labels: ["Total Payment", "Total Interest"],
		datasets: [
			{
				label: props.symbol ?? '$',
				data: [props.payment ?? 1, props.interest ?? 1],
				backgroundColor: ["#1155F7", "#91C6FD"],
				borderColor: ["#1155F7", "#91C6FD"],
				borderWidth: 1,
			},
		],
	};

	// ---------------------- RETURN ------------------------------
  return <Pie data={data} options={options} />;
}
