import { useContext, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import arrow from "../media/arrow-icon.svg";

import AddonNumberInput from "../minicomponents/AddonNumberInput";
import RightAddonNumberInput from "../minicomponents/RightAddonNumberInput";
import RightAddonYrMo from "../minicomponents/RightAddonYrMo";
import LoanAmountInput from "../minicomponents/LoanAmountInput";
import RangeSlider from "../minicomponents/RangeSlider";

import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

const MultiStepForm1 = () => {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/
	// navigate & context
	const navigate = useNavigate();
	const {
		user,
		setLoanResultData,
		setLoanReset,
		setSaveLoan,
		storeLoans,
		storeLoansBuffer,
		getLoansToCompareQuantity,
	} = useContext(AppContext);

	// for buttons
	const [disableBtnPrev, setDisableBtnPrev] = useState(true);
	const [disableBtnNext, setDisableBtnNext] = useState(true);

	// steps
	const [step, setStep] = useState(1);

	// purpose
	const [purpose, setPurpose] = useState(null);

	// current rent
	const [currentRent, setCurrentRent] = useState(null);

	// profesion
	const [profession, setProfession] = useState("salaried");
	const [salariedType, setSalariedType] = useState("public");
	const [professionValue, setProfessionValue] = useState("");

	// montly income
	const [montlyIncome, setMontlyIncome] = useState(null);

	// for ages
	const agesArr = [];
	for (let i = 18; i < 101; i++) {
		agesArr.push(i);
	}
	const [age, setAge] = useState(-1);

	// gender
	const [gender, setGender] = useState(null);

	// loan amount
	const [loanAmount, setLoanAmount] = useState({
		currency: null,
		amount: null,
	});

	// interest rate
	const [interestRate, setInterestRate] = useState(0);

	// tenure
	const [tenure, setTenure] = useState({
		type: null,
		value: null,
	});

	// calculate
	const [isCalculated, setIsCalculated] = useState(false);

	// compare
	const [isComparing, setIsComparing] = useState(false);

	// save
	const [isSaving, setIsSaving] = useState(false);

	/* ----------------------------------------------------------------------------------------
	Handles
	-------------------------------------------------------------------------------------------*/
	// purpose
	const handlePurpose = (value) => {
		setPurpose(value);
		setDisableBtnNext(false);
	};

	// current rent
	const handleCurrentRent = (value) => {
		setCurrentRent(Number(value));
		setDisableBtnNext(false);
	};

	// current rent range
	const handleCurrentRentRange = (value) => {
		let finalValue = Number(value) * 50;
		handleCurrentRent(finalValue);
	};

	// profession
	const handleProfession = (ev) => {
		setProfessionValue(ev.target.value);
		setDisableBtnNext(false);
	};

	// montly income
	const handleMontlyIncome = (value) => {
		setMontlyIncome(Number(value));
		setDisableBtnNext(false);
	};

	// age
	const handleAge = (value) => {
		let finalValue = Number(value);

		// disable btn
		if (finalValue < 18) setDisableBtnNext(true);
		else {
			setAge(finalValue);
			setDisableBtnNext(false);
		}
	};

	// handle gender
	const handleGender = (ev) => {
		let value = ev.target.value;

		if (value.length === 0) setDisableBtnNext(true);
		else {
			setGender(value);
			setDisableBtnNext(false);
		}
	};

	// btn previus
	const handlePrevious = () => {
		// condiciones
		switch (step) {
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
				setStep(step - 1);
				setDisableBtnPrev(false);
				setDisableBtnNext(false);
				break;

			default:
				setStep(step - 1);
				setDisableBtnPrev(true);
				setDisableBtnNext(false);
				break;
		}
		setLoanResultDataObj();
	};

	// btn next
	const handleNext = () => {
		// condiciones
		switch (step) {
			case 1:
				setStep(step + 1);
				setDisableBtnPrev(false);
				if (!currentRent) setDisableBtnNext(true);
				break;

			case 2:
				setStep(step + 1);
				setDisableBtnPrev(false);
				if (professionValue === "") setDisableBtnNext(true);
				break;

			case 3:
				setStep(step + 1);
				setDisableBtnPrev(false);
				if (!montlyIncome) setDisableBtnNext(true);
				break;

			case 4:
				setStep(step + 1);
				setDisableBtnPrev(false);
				if (!age) setDisableBtnNext(true);
				break;

			case 5:
				setStep(step + 1);
				setDisableBtnPrev(false);
				if (!gender || gender.length === 0) setDisableBtnNext(true);
				break;

			default:
				setStep(step + 1);
				setDisableBtnNext(true);
				break;
		}

		setLoanResultDataObj();
	};

	// restart
	const handleStartOver = () => {
		setPurpose(null);
		setCurrentRent(null);
		setProfessionValue("");
		setMontlyIncome(null);
		setAge(-1);
		setGender(null);
		setStep(1);

		setDisableBtnPrev(true);

		// reset loan context
		setLoanReset();
	};

	// check calc
	const handleCheckCalc = () => {
		// ---------------------- start calc
		// calc tenure
		let tenureInMonths = tenure.value;
		if (tenure.type === "yr") tenureInMonths = tenure.value * 12;

		// Convertir la tasa de interés a porcentaje
		let interestRatePercentPerMonth = interestRate / 12 / 100;

		// Calcular el factor de interés
		let factorInteres = Math.pow(
			1 + interestRatePercentPerMonth,
			tenureInMonths
		);

		// Calcular la EMI utilizando la fórmula
		let emi =
			(loanAmount.amount * interestRatePercentPerMonth * factorInteres) /
			(factorInteres - 1);
		// fix emi to 2 decimal places
		emi = parseFloat(emi.toFixed(2));

		// Calc interest rate
		let totalInterest = emi * tenureInMonths - Number(loanAmount.amount);

		// Calc interest total
		const totalPrincipalPlusInterest =
			Number(loanAmount.amount) + totalInterest;

		// save to context
		setLoanResultData({
			// loan type
			loan_type: 0,
			// more info
			purpose: purpose,
			current_rent: currentRent,
			profession: professionValue,
			montly_income: montlyIncome,
			age: age,
			gender: gender,
			// loan data
			ROI: interestRate,
			tenure: tenure,
			EMI: emi,
			loanAmount: loanAmount,
			// calcs
			totalInterest: totalInterest,
			totalPrinciplePlusInterest: totalPrincipalPlusInterest,
		});

		// set is calculated
		setIsCalculated(true);
	};

	const setLoanResultDataObj = () => {
		setLoanResultData({
			// loan type
			loan_type: 0,
			// more info
			purpose: purpose,
			current_rent: currentRent,
			profession: professionValue,
			montly_income: montlyIncome,
			age: age,
			gender: gender,
			// loan data
			ROI: interestRate,
			tenure: tenure,
			loanAmount: loanAmount,
		});
	};

	// handle restart
	const handleRestartLoan = () => {
		// set is calculated
		setIsCalculated(false);
		handleStartOver();
	};

	// add to compare
	const handleAddToCompare = () => {
		if (user) {
			setIsComparing(true);
			storeLoans();
			setIsComparing(false);
			handleRestartLoan();
		} else if (!user && getLoansToCompareQuantity() < 2) {
			setIsComparing(true);
			storeLoans();
			handleRestartLoan();
			setIsComparing(false);
		} else {
			storeLoansBuffer();
			navigate("/login");
		}
	};

	// const saving loan
	const handleSaveLoan = () => {
		if (user) {
			setIsSaving(true);
			setSaveLoan().then(() => {
				setIsSaving(false);
				handleRestartLoan();
			});
		} else {
			storeLoans(true);
			handleRestartLoan();
			navigate("/login");
		}
	};

	/* ----------------------------------------------------------------------------------------
	render by step
	-------------------------------------------------------------------------------------------*/
	const renderStepContent = () => {
		switch (step) {
			case 1:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="row mb-3 pt-2 pb-0">
								<legend className="fs-4 fw-bold mb-4">Purpose</legend>

								<div className="form-check my-2">
									<input
										className="form-check-input"
										type="radio"
										name="gridRadios"
										id="primaryHouse"
										value="Primary House"
										defaultChecked={purpose === "primaryHouse"}
										onClick={(e) => handlePurpose(e.target.value)}
									/>
									<label
										className="form-check-label fw-bold"
										htmlFor="primaryHouse"
									>
										Primary House (I will stay here)
									</label>
								</div>

								<div className="form-check my-2">
									<input
										className="form-check-input"
										type="radio"
										name="gridRadios"
										id="secondaryHouse"
										value="Secondary House"
										defaultChecked={purpose === "secondaryHouse"}
										onClick={(e) => handlePurpose(e.target.value)}
									/>
									<label
										className="form-check-label fw-bold"
										htmlFor="secondaryHouse"
									>
										Secondary (will be rented out or for investment)
									</label>
								</div>

								<div className="form-check my-2">
									<input
										className="form-check-input"
										type="radio"
										name="gridRadios"
										id="houseNotSure"
										value="Others"
										defaultChecked={purpose === "houseNotSure"}
										onClick={(e) => handlePurpose(e.target.value)}
									/>
									<label
										className="form-check-label fw-bold"
										htmlFor="houseNotSure"
									>
										Others (Not sure)
									</label>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 2:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="row mb-3 pt-2 pb-0">
								<legend className="fs-4 fw-bold mb-5">Current Rent</legend>

								<div className="col-sm-4">
									<AddonNumberInput
										id={"currentRentNumber"}
										name={"currentRentNumber"}
										min={"0"}
										max={"5000000"}
										placeholder={"150"}
										addontext={"$"}
										arialabel={"Current rent"}
										value={currentRent}
										onChange={(e) => handleCurrentRent(e.target.value)}
									/>
								</div>

								<div className="col-sm-8">
									<RangeSlider
										onChange={(e) => handleCurrentRentRange(e.target.value)}
									/>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 3:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm mb-3 pt-2 pb-0">
								<legend className="fs-4 fw-bold mb-4">Profession</legend>

								<div className="row">
									<div className="form-check col-sm mb-4">
										<input
											className="form-check-input"
											type="radio"
											name="professionRadios"
											id="salaried"
											value="salaried"
											onChange={(e) => setProfession(e.currentTarget.value)}
											defaultChecked={
												profession === "salaried" ||
												professionValue.includes("public") ||
												professionValue.includes("private-")
											}
										/>

										<label
											className="form-check-label  fw-bold"
											htmlFor="salaried"
										>
											Salaried
										</label>

										{profession === "salaried" && (
											<fieldset className="row ms-3 mt-2">
												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="publicprivate"
														id="public"
														value="public"
														onChange={(e) => {
															setSalariedType(e.currentTarget.value);
															handleProfession(e);
														}}
														defaultChecked={
															salariedType === "public" &&
															professionValue === "public"
														}
													/>
													<label className="form-check-label" htmlFor="public">
														Public
													</label>
												</div>

												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="publicprivate"
														id="private"
														value="private"
														onChange={(e) =>
															setSalariedType(e.currentTarget.value)
														}
														defaultChecked={
															salariedType === "private" ||
															professionValue.includes("private-")
														}
													/>
													<label className="form-check-label" htmlFor="private">
														Private
													</label>

													{salariedType === "private" && (
														<fieldset className="row ms-3 mt-2">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	name="privatetype"
																	value="private-software"
																	id="software"
																	onChange={(e) => handleProfession(e)}
																	defaultChecked={
																		professionValue === "private-software"
																	}
																/>

																<label
																	className="form-check-label"
																	htmlFor="software"
																>
																	Software
																</label>
															</div>

															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	name="privatetype"
																	value="private-other"
																	id="otherprivate"
																	onChange={(e) => handleProfession(e)}
																	defaultChecked={
																		professionValue === "private-other"
																	}
																/>

																<label
																	className="form-check-label"
																	htmlFor="otherprivate"
																>
																	Other Private
																</label>
															</div>
														</fieldset>
													)}
												</div>
											</fieldset>
										)}
									</div>

									<div className="form-check col-sm mb-4">
										<input
											className="form-check-input"
											type="radio"
											name="professionRadios"
											id="business"
											value="business"
											onChange={(e) => setProfession(e.currentTarget.value)}
											defaultChecked={
												profession === "business" ||
												professionValue.includes("business-")
											}
										/>

										<label
											className="form-check-label fw-bold"
											htmlFor="business"
										>
											Business
										</label>

										{profession === "business" && (
											<fieldset className="row ms-3 mb-3 mt-2">
												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="businesstype"
														id="sme"
														value="business-sme"
														onChange={(e) => handleProfession(e)}
														defaultChecked={professionValue === "business-sme"}
													/>

													<label className="form-check-label" htmlFor="sme">
														SME
													</label>
												</div>

												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="businesstype"
														id="small"
														value="business-small"
														onChange={(e) => handleProfession(e)}
														defaultChecked={
															professionValue === "business-small"
														}
													/>

													<label className="form-check-label" htmlFor="small">
														Small
													</label>
												</div>
											</fieldset>
										)}
									</div>
								</div>

								<div className="row">
									<div className="form-check col-sm">
										<input
											className="form-check-input"
											type="radio"
											name="professionRadios"
											id="student"
											value="student"
											onChange={(e) => {
												setProfession(e.currentTarget.value);
												handleProfession(e);
											}}
											defaultChecked={
												profession === "student" ||
												professionValue === "student"
											}
										/>
										<label
											className="form-check-label fw-bold"
											htmlFor="student"
										>
											Student
										</label>
									</div>

									<div className="form-check col-sm">
										<input
											className="form-check-input"
											type="radio"
											name="professionRadios"
											id="homeMaker"
											value="homemaker"
											onChange={(e) => {
												setProfession(e.currentTarget.value);
												handleProfession(e);
											}}
											defaultChecked={
												profession === "homemaker" ||
												professionValue === "homemaker"
											}
										/>

										<label
											className="form-check-label fw-bold"
											htmlFor="homeMaker"
										>
											Home Maker
										</label>
									</div>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 4:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm mb-3 pt-2 pb-0">
								<legend className="fs-4 fw-bold mb-5">Monthly Income</legend>

								<AddonNumberInput
									id={"monthlyIncome"}
									name={"monthlyIncome"}
									min={"0"}
									max={"500000"}
									placeholder={"550.00"}
									addontext={"$"}
									arialabel={"monthly income"}
									onChange={(e) => handleMontlyIncome(e.currentTarget.value)}
									value={montlyIncome}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 5:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="row mb-3 pt-2 pb-0">
								<legend className="fs-4 fw-bold mb-4">Age</legend>

								<div className="input-group my-2">
									<select
										className="form-select border-bluishgrey"
										aria-label="select age"
										onChange={(e) => handleAge(e.currentTarget.value)}
										value={age}
									>
										<option value="-1">Select your age</option>
										{agesArr.map((age, index) => (
											<option key={index} value={age}>
												{age}
											</option>
										))}
									</select>

									<span className="input-group-text border-bluishgrey">
										Years
									</span>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 6:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="row mb-3 pt-2 pb-0">
								<legend className="col-form-label col-sm-2 pt-0 fw-bold fs-4 mb-4">
									Gender
								</legend>

								<div className="my-2">
									<select
										className="form-select border-bluishgrey"
										aria-label="select age"
										value={gender}
										onChange={(e) => handleGender(e)}
									>
										<option value="">Select your gender</option>
										<option value="male">Male</option>
										<option value="female">Female</option>
										<option value="non-binary">Non-Binary</option>
										<option value="other">Other</option>
										<option value="no-choose">Do not wish to choose</option>
									</select>
								</div>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			case 7:
				return (
					<Container>
						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Loan Amount</legend>

								<LoanAmountInput
									id={"loanAmount1"}
									name={"loanAmount1"}
									min={"0"}
									max={"100000000"}
									placeholder={"5,000,000"}
									arialabel={"loan amount"}
									onChange={(e) => setLoanAmount(e)}
								/>
							</fieldset>

							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Interest Rate</legend>

								<RightAddonNumberInput
									id={"interestRate1"}
									name={"interestRate1"}
									min={"0"}
									max={"20"}
									placeholder={"8.00"}
									addontext={"%"}
									arialabel={"interest rate"}
									onChange={(e) => setInterestRate(e.currentTarget.value)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}

						{/* FORM ROW */}
						<div className="row mb-3">
							<fieldset className="col-sm-6 mb-3 pt-2 pb-0">
								<legend className="fs-6 fw-bold">Tenure</legend>

								<RightAddonYrMo
									id={"tenure1"}
									name={"tenure1"}
									min={"0"}
									max={"10000"}
									placeholder={"0"}
									addontext1={"Yr"}
									addontext2={"Mo"}
									arialabel={"tenure"}
									radiosName={"tenureRadios1"}
									radioYrId={"tenureRadioYr1"}
									radioMoId={"tenureRadioMo1"}
									onChange={(e) => setTenure(e)}
								/>
							</fieldset>
						</div>
						{/* END FORM ROW */}
					</Container>
				);

			default:
				return null;
		}
	};

	/* ----------------------------------------------------------------------------------------
	return
	-------------------------------------------------------------------------------------------*/
	return (
		<Container className="p-relative">
			{/* FORM INPUTS */}
			<Row className="align-items-center" style={{ minHeight: "360px" }}>
				{/* ARROW PREVIOUS */}
				<div className="col-1 p-0">
					<Button
						variant="darkblue"
						className="rounded-circle"
						onClick={handlePrevious}
						disabled={disableBtnPrev ? "disabled" : ""}
					>
						<img
							src={arrow}
							alt="next-arrow"
							className="p-0 mb-1 inverse"
						></img>
					</Button>
				</div>
				{/* END ARROW PREVIOUS */}

				{/* FORM STEPS */}
				<div className="col-9 mx-sm-3">
					<Form>{renderStepContent()}</Form>
				</div>
				{/* END FORM STEPS */}

				{/* ARROW NEXT */}
				<div className="col-1 p-0 text-right">
					<Button
						variant="darkblue"
						className="rounded-circle"
						onClick={handleNext}
						disabled={disableBtnNext ? "disabled" : ""}
					>
						<img src={arrow} alt="next-arrow" className="p-0 mb-1"></img>
					</Button>
				</div>
				{/* END ARROW NEXT */}
			</Row>
			{/* END FORM INPUTS */}

			{/* BUTTONS CALCULATE/START OVER */}
			<Row className="ms-sm-3 me-sm-5 g-3">
				{/* calculate */}
				<div className="col">
					<div className="d-grid">
						{step < 7 && (
							<Button
								variant="darkblue"
								className="py-2 px-4"
								type="submit"
								disabled
							>
								Calculate
							</Button>
						)}
						{step === 7 && (
							<Button
								variant="darkblue"
								className="py-2 px-4"
								type="submit"
								disabled={!loanAmount || !tenure || !interestRate}
								onClick={handleCheckCalc}
							>
								Calculate
							</Button>
						)}
					</div>
				</div>

				{/* start over */}
				{step > 1 && (
					<div className="col">
						<div className="d-grid">
							<Button
								variant="darkblue"
								className="py-2 px-4 text-nowrap"
								type="submit"
								onClick={handleStartOver}
							>
								Start Over
							</Button>
						</div>
					</div>
				)}
			</Row>

			{/* to calculated */}
			{isCalculated && (
				<div className="loan-calculated">
					<p>
						Loan calculations are complete! The results are ready for your
						review.
					</p>
					{!user && <p>You must log in to save loans</p>}
					<div className="row w-100 row-center">
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleSaveLoan}
							>
								{!isSaving && <span>Save Loan</span>}
								{isSaving && <span>Please wait...</span>}
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleRestartLoan}
							>
								Reset Loan
							</Button>
						</div>
						<div className="col-lg-3 mb-2">
							<Button
								variant="darkblue"
								className="w-100 text-nowrap"
								type="submit"
								disabled={isComparing || isSaving}
								onClick={handleAddToCompare}
							>
								{!isComparing && <span>Add to Compare</span>}
								{isComparing && <span>Please wait...</span>}
							</Button>
						</div>
					</div>
				</div>
			)}
			{/* EMD BUTTONS CALCULATE/START OVER */}
		</Container>
	);
};

export default MultiStepForm1;
