import { Pie } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

let options = { responsive: true, maintainAspectRatio: false };

let data = {
  labels: ["Total Payment", "Total Interest"],
  datasets: [
    {
      label: "$",
      data: [5200, 1023],
      backgroundColor: ["#1155F7", "#91C6FD"],
      borderColor: ["#1155F7", "#91C6FD"],
      borderWidth: 1,
    },
  ],
};

export default function LoanComparePie1() {
  return <Pie data={data} options={options} />;
}
