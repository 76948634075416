/* eslint-disable jsx-a11y/img-redundant-alt */
import LoanForm from "../loansForm/LoanForm";
import HomeCharts from "../HomeCharts";
import OwnNavbar from "../Navbar";

import homeImage from "../media/home-image.png";
import Footer from "../Footer";

import { Link, useNavigate } from "react-router-dom";

import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import LoanCompareTable from "../components/LoanCompareTable";

function HomePage({ auth }) {
	// navigate & context
	const navigate = useNavigate();
	const { user } = useContext(AppContext);

	// check if user logged
	// redirect to dashboard
	useEffect(() => {
		if (user) navigate("/dashboard");
	}, [user, navigate]);

	return (
		<>
			<OwnNavbar auth={auth} />

			{/* HERO SECTION */}
			<div className="container-md">
				<div className="row">
					<div className="col-md-6 mx-md-auto text-center">
						<img
							src={homeImage}
							className="mx-auto d-block img-fluid my-4"
							alt="Hero image"
						></img>

						<h2 className="text-dark text-center mx-auto my-4">
							Welcome to Finvestkit. A platform for all your finantial needs.
						</h2>

						<p
							className="text-dark text-center mx-auto"
							style={{ fontSize: "small" }}
						>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s
						</p>
						{!user ? (
							<Link
								to="/signup"
								className="btn btn-neonblue py-2 px-4 mt-3 mb-5"
							>
								Start for Free
							</Link>
						) : (
							<Link
								to="/dashboard"
								className="btn btn-neonblue py-2 px-4 mt-3 mb-5"
							>
								Go to dashboard
							</Link>
						)}
					</div>
				</div>
			</div>
			{/* END HERO SECTION */}

			<LoanForm />

			<LoanCompareTable />

			<HomeCharts />

			<Footer />
		</>
	);
}

export default HomePage;
