import React, { useState, createContext } from "react";
import {
	SessionStorageGetData,
	SessionStorageSetData,
	StorageGetData,
	StorageSetData,
} from "./services/Storage";
import { jwtDecode } from "jwt-decode";

import Api from "./services/Api";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
	/* ----------------------------------------------------------------------------------------
	User
	-------------------------------------------------------------------------------------------*/
	// user: token
	const userToken = () => StorageGetData("AppProvider_user");

	// user: decode token
	const userDecodeToken = (newToken = null) => {
		let data = null;

		let finalToken = newToken ?? userToken();

		if (finalToken)
			try {
				data = jwtDecode(finalToken);
			} catch (e) {}

		// return
		return data;
	};

	// user
	const [user, setUser] = useState(userDecodeToken());

	// set user data
	const setUserData = (token) => {
		// decode token
		const data = userDecodeToken(token);

		// set user
		setUser(data);

		// insert to storage
		StorageSetData("AppProvider_user", token);

		// save loan in session storage
		saveLoansFromStorageInDB();
		setLoansBufferToCompareList();
	};

	const userLogout = () => {
		StorageSetData("AppProvider_user", null);
		setUser(null);
	};

	// user
	const [firebaseAuth, setFirebaseAuthData] = useState();

	// set user data
	const setFirebaseAuthDataData = (auth) => {
		// set user
		setFirebaseAuthData(auth);
	};

	/* ----------------------------------------------------------------------------------------
	Loans management
	-------------------------------------------------------------------------------------------*/
	// loan result
	const defaultLoanResult = {
		// other data
		loan_type: 0,
		purpose: null,
		current_rent: null,
		profession: null,
		montly_income: null,
		age: null,
		gender: null,
		// other loan data
		vehicle_type: null,
		vehicle_state: null,
		vehicle_fuel: null,
		// other loan data
		moratorium: null,
		moratorium_type: null,
		moratorium_time: null,
		// calc
		ROI: 0,
		tenure: {
			type: "Yr",
			value: 0,
		},
		EMI: 0,
		// to calc...
		loanAmount: {
			currency: "USD",
			amount: 0,
		},
		totalInterest: 0,
		totalPrinciplePlusInterest: 0,
	};

	const [loanResult, setLoanResult] = useState(defaultLoanResult);

	// set loan result
	const setLoanResultData = (newLoanResult) => {
		setLoanResult((prevLoanResult) => {
			return { ...prevLoanResult, ...newLoanResult };
		});
	};

	// set reset loan
	const setLoanReset = () => {
		setLoanResult(defaultLoanResult);
	};

	// set save loan
	const setSaveLoan = (loan = null) => {
		return new Promise((resolve, error) => {
			Api("post", "users/setLoan", loan || loanResult)
				.then((res) => {
					if (res.success) {
						// setNewLoansData(res.data);
					}
					resolve(res);
				})
				.catch((err) => error(err));
		});
	};

	// loans data
	const [loansData, setLoansData] = useState([]);

	// const setNewLoansData = (loanData) => {
	// 	// check if exists
	// 	if (loansData.find((e) => e.id === loanData.id)) return;
	// 	// set
	// 	loanData.totalInterest = loanData.EMI * loanData.Tenure - loanData.amount;
	// 	loanData.totalPrinciplePlusInterest =
	// 		Number(loanData.amount) + Number(loanData.totalInterest);

	// 	// set symbol
	// 	loanData.amount_symbol = "$";
	// 	if (loanData.loanAmount.currency === "inr") loanData.amount_symbol = "₹";
	// 	if (loanData.loanAmount.currency === "eur") loanData.amount_symbol = "€";

	// 	// create new content
	// 	const newLoanData = [loanData, ...loansData.slice(0, 4 - 1)];

	// 	// set loans data
	// 	setLoansData(newLoanData);
	// };

	// Loans Cache
	const sessionStorageKeys = {
		loansToCompare: "loansToCompare",
		loanToSave: "loanToSave",
		loanBuffer: "loanBuffer",
	};

	const [loansToCompare, setLoansToCompare] = useState([]);

	const storeLoans = (saveInDb = false) => {
		if (saveInDb) {
			storeLoansToSaveInDB();
		} else {
			storeLoansToCompare();
		}
	};

	/**
	 * Set loanResult in loanBuffer
	 */
	const storeLoansBuffer = () => {
		SessionStorageSetData(sessionStorageKeys.loanBuffer, loanResult);
	};

	const storeLoansToSaveInDB = () => {
		const loansInStorage = SessionStorageGetData(sessionStorageKeys.loanToSave);
		const newLoansObj = loansInStorage
			? [...loansInStorage, loanResult]
			: [loanResult];
		SessionStorageSetData(sessionStorageKeys.loanToSave, newLoansObj);
	};

	/**
	 * Add new Loan to loansToCompare
	 * @param {any} loan if loan doesn't exists it store loanResult by default
	 */
	const storeLoansToCompare = (loan = null) => {
		const loansInStorage = SessionStorageGetData(
			sessionStorageKeys.loansToCompare
		);
		const newLoan = loan ? loan : loanResult;
		const newLoansObj = loansInStorage
			? [...loansInStorage, newLoan]
			: [newLoan];
		SessionStorageSetData(sessionStorageKeys.loansToCompare, newLoansObj);
		setLoansToCompare(newLoansObj);
	};

	/**
	 * Set loanBuffer in LoansToCompare
	 */
	const setLoansBufferToCompareList = () => {
		const loans = SessionStorageGetData(sessionStorageKeys.loanBuffer);
		if (loans) {
			SessionStorageSetData(sessionStorageKeys.loanBuffer, null);
			storeLoansToCompare(loans);
		}
	};

	const saveLoansFromStorageInDB = () => {
		const loansInStorage = SessionStorageGetData(sessionStorageKeys.loanToSave);
		if (!loansInStorage) return;
		for (const loan of loansInStorage) {
			setSaveLoan(loan);
		}
		SessionStorageSetData(sessionStorageKeys.loanToSave, null);
	};

	const getLoansToCompareQuantity = () => {
		const loans = SessionStorageGetData(sessionStorageKeys.loansToCompare);
		return loans ? loans.length : 0;
	};

	/* ----------------------------------------------------------------------------------------
	Return
	-------------------------------------------------------------------------------------------*/
	return (
		<AppContext.Provider
			value={{
				// user
				user,
				setUserData,
				userLogout,
				// loan
				loanResult,
				setLoanResultData,
				setLoanReset,
				setSaveLoan,
				// loan data
				loansData,
				setLoansData,
				// auth
				firebaseAuth,
				setFirebaseAuthDataData,
				// cache
				storeLoans,
				storeLoansBuffer,
				getLoansToCompareQuantity,
				loansToCompare,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;
