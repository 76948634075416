import LoanForm from "../loansForm/LoanForm";
import LoanPaymentYears from "../charts/LoanPaymentYears";
import LoanCompareTable from "../components/LoanCompareTable";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PortfolioPieChart from "../charts/PortfolioPieChart";
import LineChartLoanCompare from "../charts/LineChartLoanCompare";
import LoansAccordionTable from "../components/LoansAccordionTable";

import OwnNavbar from "../Navbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";

function DashboardPage({ auth }) {
	// navigate & context
	const navigate = useNavigate();
	const { user } = useContext(AppContext);

	// check if user logged
	// redirect to login page
	useEffect(() => {
		if (!user) navigate("/login");
	});

	// return
	return (
		<>
			<OwnNavbar auth={auth} />

			<LoanForm style={{ marginTop: "2rem" }} />

			<LoanCompareTable />

			{/* BAR CHART */}
			<Container
				className="border border-2 my-4 bg-white p-5"
				style={{ borderRadius: "15px" }}
			>
				<Row>
					<LoanPaymentYears />
				</Row>
			</Container>
			{/* END BAR CHART */}

			{/* PORTFOLIO SECTION */}
			<Container className="mt-5 px-0">
				<Row>
					<h2 id="portfolio">Your Portfolio</h2>
					<hr />
				</Row>

				<Row className="mb-5 mt-4">
					<Col>
						<PortfolioPieChart />
					</Col>

					<div className="col-7">
						<div className="card p-3">
							<div className="card-body pt-2 px-0">
								<div className="container-fluid px-0">
									<div className="row">
										<div className="col">
											<div className="table-responsive">
												<table className="table table-hover text-center">
													<thead>
														<tr>
															<th className="w-25"></th>
															<th className="table-primary">Total Amount</th>
															<th className="table-primary">EMI Yearly</th>
															<th className="table-primary">EMI Monthly</th>
														</tr>
													</thead>

													<tbody>
														<tr>
															<th className="text-start w-25">Loans</th>
															<td className="fw-bold text-secondary">
																Rs. 4444
															</td>
															<td className="fw-bold text-secondary">
																Rs. 444
															</td>
															<td className="fw-bold text-secondary">Rs. 44</td>
														</tr>

														<tr>
															<th className="text-start w-25">Mutual Funds</th>
															<td className="fw-bold text-secondary">
																Rs. 2222
															</td>
															<td className="fw-bold text-secondary">
																Rs. 222
															</td>
															<td className="fw-bold text-secondary">Rs. 22</td>
														</tr>

														<tr>
															<th className="text-start w-25">Insurance</th>
															<td className="fw-bold text-secondary">
																Rs. 3333
															</td>
															<td className="fw-bold text-secondary">
																Rs. 333
															</td>
															<td className="fw-bold text-secondary">Rs. 33</td>
														</tr>

														<tr>
															<th className="text-start w-25">TOTAL</th>
															<td className="fw-bold">Rs. 9999</td>
															<td className="fw-bold">Rs. 999</td>
															<td className="fw-bold">Rs. 99</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Row>
			</Container>
			{/* END PORTFOLIO SECTION */}

			{/* LOANS SECTION */}
			<LoansAccordionTable />
			{/* END LOANS SECTION */}

			{/* LINE CHART */}
			<Container className="mt-5 px-0">
				<Row>
					<h2 id="portfolio">Loans Compare</h2>
					<hr />
				</Row>
			</Container>

			<Container
				className="border border-2 mt-2 mb-4 bg-white p-5"
				style={{ borderRadius: "15px" }}
			>
				<Row>
					<LineChartLoanCompare />
				</Row>
			</Container>
			{/* END LINE CHART */}

			<Footer />
		</>
	);
}

export default DashboardPage;
