import CryptoJS from "crypto-js";

// pass
const secretPass = "Xiu19cnh68vuEyAb";

/**
 * SetData to storage
 * @param {*} name
 * @param {*} data
 */
const StorageSetData = (name, data) => {
	// variables
	let jsonData = JSON.stringify(data);

	// enc data
	const encData = CryptoJS.AES.encrypt(jsonData, secretPass).toString();

	// set storage
	localStorage.setItem(name, encData);
};

/**
 * GetData from storage
 * @param {*} name
 * @returns
 */
const StorageGetData = (name) => {
	// data from storage
	let data = localStorage.getItem(name);
	if (!data) return null;

	// dec data
	let decData = null;
	try {
		decData = CryptoJS.AES.decrypt(data, secretPass).toString(
			CryptoJS.enc.Utf8
		);
	} catch (e) {
		return null;
	}

	// check
	if (!decData) return null;

	// parse data & return
	return JSON.parse(decData);
};

const SessionStorageSetData = (name, data) => {
	// variables
	let jsonData = JSON.stringify(data);

	// enc data
	const encData = CryptoJS.AES.encrypt(jsonData, secretPass).toString();

	// set storage
	sessionStorage.setItem(name, encData);
};

const SessionStorageGetData = (name) => {
	// data from storage
	let data = sessionStorage.getItem(name);
	if (!data) return null;

	// dec data
	let decData = null;
	try {
		decData = CryptoJS.AES.decrypt(data, secretPass).toString(
			CryptoJS.enc.Utf8
		);
	} catch (e) {
		return null;
	}

	// check
	if (!decData) return null;

	// parse data & return
	return JSON.parse(decData);
};

// export
export {
	StorageGetData,
	StorageSetData,
	SessionStorageSetData,
	SessionStorageGetData,
};
