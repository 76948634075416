import React from "react";

const Modal = ({ isOpen, message, onClose }) => {
	if (!isOpen) return null;

	return (
		<div
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				zIndex: 1000,
				animation: "fadeIn 0.3s",
			}}
		>
			<div
				style={{
					padding: "35px 20px",
					background: "white",
					borderRadius: "10px",
					boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					maxWidth: "500px", // Ensure modal is responsive
					boxSizing: "border-box", // Ensures padding is included in width
					animation: "scaleUp 0.3s",
				}}
			>
				<p
					style={{
						fontSize: "16px",
						color: "#333",
						textAlign: "center",
						marginBottom: "20px",
					}}
				>
					{message}
				</p>
				<button
					onClick={onClose}
					style={{
						padding: "10px 20px",
						fontSize: "16px",
						color: "white",
						background: "#007BFF",
						border: "none",
						borderRadius: "5px",
						cursor: "pointer",
						transition: "background-color 0.2s",
					}}
					onMouseOver={({ target }) =>
						(target.style.backgroundColor = "#0056b3")
					}
					onMouseOut={({ target }) =>
						(target.style.backgroundColor = "#007BFF")
					}
				>
					Close
				</button>
			</div>
		</div>
	);
};

export default Modal;
