export default function TableNumberInput() {
  return (
    <td className="px-0" style={{ background: "rgba(145, 198, 253, 0.2)",}}>
      <input
        type="number"
        className="form-control border-bluishgrey d-inline-block w-50"
        style={{ padding: "1px 0px 1px 1px" }}
        min="0"
        max="500000000"
        placeholder="0"
      />
    </td>
  );
}
