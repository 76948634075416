/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { signOut } from "firebase/auth";

import { useContext, useEffect, useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Link, useNavigate } from "react-router-dom";

import logo from "./logo.png";
import finvestWhiteLogo from "./media/Finvestkit-white-logo-login.svg";
// import profilePic from "./media/profile-pic.png";
import defaultProfilePic from "./media/default-profile.png";

import { AppContext } from "./AppContext";

function OwnNavbar({ auth }) {
	const [show, setShow] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const dropdownRef = useRef(null);

	const toggleDropdownProfile = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	// Manejar clics fuera del dropdown
	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false); // Cierra el dropdown si se hace clic fuera
			}
		}

		// Agregar listener de eventos cuando el dropdown está abierto
		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		// Limpieza del efecto
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isDropdownOpen]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const logout = () => {
		signOut(auth)
			.then(() => {
				userLogout();
				navigate("/login");
			})
			.catch((error) => {
				console.error("Error logging out:", error);
			});
	};

	// navigate & context
	const navigate = useNavigate();
	const { user, userLogout } = useContext(AppContext);

	return (
		<Navbar
			sticky="top"
			expand="lg"
			bg="light"
			data-bs-theme="light"
			className="bg-white border-bottom border-2"
			style={{
				fontFamily:
					'"Poppins", "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif',
			}}
		>
			{/* OFFCANVAS TOGGLER */}
			<Button
				variant="secondary"
				className="bg-white mx-3 rounded-circle py-2"
				onClick={handleShow}
			>
				<span className="navbar-toggler-icon"></span>
			</Button>
			{/* END OFFCANVAS TOGGLER */}

			{/* OFFCANVAS LEFT MENU */}
			<Offcanvas
				show={show}
				onHide={handleClose}
				scroll={true}
				className="bg-darkblue w-25"
			>
				<Offcanvas.Header>
					<img
						src={finvestWhiteLogo}
						className="mx-auto d-block w-50"
						alt="Finvest kit logo"
					></img>
				</Offcanvas.Header>

				<Offcanvas.Body className="px-0">
					<ul className="nav flex-column">
						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<Link
								to="/dashboard"
								className="nav-link active text-light fs-5 align-top"
								onClick={handleClose}
							>
								<i className="material-icons text-light mx-3 fs-5 align-baseline">
									create
								</i>
								Dashboard
							</Link>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="https://loans3admin.netlify.app/"
								target="_blank"
								rel="noreferrer"
							>
								<i className="material-icons text-skyblue fs-5 mx-3 align-baseline">
									flag
								</i>
								Admin
							</a>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							{/* <Link
                to="/dashboard#portfolio"
                className="nav-link active fs-4 align-top"
              >
                <i className="material-icons text-secondary mx-3 align-baseline">
                  next_week
                </i>
                Portfolio
              </Link> */}

							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="#portfolio"
							>
								<i className="material-icons text-skyblue mx-3 fs-5 align-baseline">
									next_week
								</i>
								Portfolio
							</a>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="#loans"
							>
								<i className="material-icons text-skyblue mx-3 fs-5 align-baseline">
									save
								</i>
								Loans
							</a>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="#"
							>
								<i className="material-icons text-skyblue mx-3 fs-5 align-baseline">
									send
								</i>
								Lorem Ipsum
							</a>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="#"
							>
								<i className="material-icons text-skyblue mx-3 fs-5 align-baseline">
									import_contacts
								</i>
								Lorem Ipsum
							</a>
						</li>

						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
						<li className="nav-item">
							<a
								className="nav-link active text-skyblue fs-5 align-top"
								aria-current="page"
								href="#"
							>
								<i className="material-icons text-skyblue mx-3 fs-5 align-baseline">
									forum
								</i>
								Lorem Ipsum
							</a>
						</li>
						<hr
							className="my-1 bg-base"
							style={{ height: "2px", borderWidth: "0" }}
						/>
					</ul>
				</Offcanvas.Body>
			</Offcanvas>
			{/* END OFFCANVAS LEFT MENU */}

			<Navbar.Brand href="#home" className="p-0">
				<Link to="/">
					<img src={logo} style={{ height: "3rem" }} alt="Finvest kit logo" />
				</Link>
			</Navbar.Brand>

			<Navbar.Toggle aria-controls="basic-navbar-nav" />

			<Navbar.Collapse id="basic-navbar-nav">
				{/* LINKS TO EMI CALCULATOR/OFFERS/BLOG/CONTACT US */}
				<Nav className="fw-bold m-auto">
					<Nav.Link
						href="#calculator"
						className="fs-6 text-dark mx-4 text-nowrap"
					>
						EMI Calculator
					</Nav.Link>

					<Nav.Link href="#link" className="fs-6 fw-normal text-secondary mx-4">
						Offers
					</Nav.Link>

					<Nav.Link href="#link" className="fs-6 fw-normal text-secondary mx-4">
						Blog
					</Nav.Link>

					<Nav.Link
						href="#link"
						className="fs-6 fw-normal text-secondary mx-4 text-nowrap"
					>
						Contact Us
					</Nav.Link>
				</Nav>
				{/* END LINKS TO EMI CALCULATOR/OFFERS/BLOG/CONTACT US */}

				{!user ? (
					<Nav className="ms-auto me-3">
						<Nav.Link href="#">
							<Link to="/login">
								<Button
									variant="skyblue"
									className="login-btn border-0 text-darkblue px-4 mx-4 mx-md-0 text-nowrap"
								>
									Log In
								</Button>
							</Link>
						</Nav.Link>

						<Nav.Link href="#">
							<Link to="/signup">
								<Button
									variant="primary"
									className="signup-btn border-0 px-4 mx-4 mx-md-0 text-nowrap"
								>
									Sign Up
								</Button>
							</Link>
						</Nav.Link>
					</Nav>
				) : (
					<ul className="navbar-nav justify-content-end me-sm-3 mt-sm-2">
						<li className="nav-item dropdown" ref={dropdownRef}>
							<button
								className="nav-link dropdown-toggle font-weight-bold text-body px-2 text-nowrap"
								href=""
								data-bs-toggle="dropdown"
								onClick={(e) => {
									e.preventDefault();
									toggleDropdownProfile();
								}}
							>
								<img
									src={defaultProfilePic}
									className="rounded mx-2"
									style={{ width: "2rem" }}
									alt={user ? user.name + "picture" : "Default user Picture"}
								/>
								{user ? user.name : "Sophia Emma"}
							</button>

							<ul className={`dropdown-menu ${isDropdownOpen ? " show" : ""}`}>
								<li>
									<button className="dropdown-item" onClick={logout}>
										Log Out
									</button>
								</li>
							</ul>
						</li>

						{/* <li className="nav-item d-xl-none px-1 d-flex align-items-center">
							<a
								href=""
								className="nav-link text-body p-0"
								id="iconNavbarSidenav"
							>
								<div className="sidenav-toggler-inner">
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
								</div>
							</a>
						</li> */}
					</ul>
				)}
			</Navbar.Collapse>
		</Navbar>
	);
}

export default OwnNavbar;
