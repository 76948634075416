/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import googleLogo from "../media/google-login.svg";
import facebookLogo from "../media/facebook-login.svg";
import twitterLogo from "../media/twitter-login.svg";
import finvestFLogo from "../media/Finvestkit-f-logo-login.svg";
import finvestWhiteLogo from "../media/Finvestkit-white-logo-login.svg";
import loginImage from "../media/login-image.png";

import {
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	TwitterAuthProvider,
	createUserWithEmailAndPassword,
	updateProfile,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { StorageSetData } from "../services/Storage";
import Api from "../services/Api";
import { AppContext } from "../AppContext";

export default function SignupPage({ auth }) {
	/* FIREBASE AUTH */
	// Google
	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				const accessToken = user.accessToken;
				setOnSending(true);
				Api("post", "users/getUserWithSocial", { accessToken: accessToken })
					.then((res) => {
						setUserData(res.data);
						navigate("/dashboard");
					})
					.catch((error) => {
						setOnSending(false);
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// Facebook
	const signInWithFacebook = () => {
		const provider = new FacebookAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				const accessToken = user.accessToken;
				setOnSending(true);
				Api("post", "users/getUserWithSocial", { accessToken: accessToken })
					.then((res) => {
						setUserData(res.data);
						navigate("/dashboard");
					})
					.catch((error) => {
						setOnSending(false);
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// Twitter
	const signInWithTwitter = () => {
		const provider = new TwitterAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				const accessToken = user.accessToken;
				setOnSending(true);
				Api("post", "users/getUserWithSocial", { accessToken: accessToken })
					.then((res) => {
						setUserData(res.data);
						navigate("/dashboard");
					})
					.catch((error) => {
						setOnSending(false);
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	/* FIREBASE AUTH */

	// navigate & context
	const navigate = useNavigate();
	const { user, setUserData } = useContext(AppContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [passwordType, setPasswordType] = useState("password");

	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [nameError, setNameError] = useState("");

	const [remember, setRemember] = useState(true);

	const [onSending, setOnSending] = useState(false);

	// check if user logged
	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	const register = async (email, password, name) => {
		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;

			await updateProfile(user, {
				displayName: name,
			});

			setOnSending(true);
			const accessToken = await user.getIdToken();
			const res = await Api("post", "users/setUser", {
				accessToken: accessToken,
				name: name,
			});
			if (!res.success) {
				if (res.message.email) {
					setEmailError(res.message.email);
				}
				setOnSending(false);
			} else {
				setUserData(res.data);
				navigate("/dashboard");
			}
		} catch (error) {
			if (error.customData._tokenResponse.error.message === "EMAIL_EXISTS") {
				setEmailError(error.code);
			}
			// Re-enable the button in case of error
			setOnSending(false);
		}
	};

	const onSubmitForm = (ev) => {
		// prev default
		ev.preventDefault();

		if (!name.trim()) {
			setNameError("Name is required.");
			return;
		}

		if (!email.trim()) {
			setNameError("Email is required.");
			return;
		}

		if (!password.trim()) {
			setNameError("Password is required.");
			return;
		}

		// clear messages error
		setEmailError("");
		setPasswordError("");
		setNameError("");

		// set to storage if remember is enabled
		if (remember)
			StorageSetData("login_signup_data", {
				email: email,
				password: password,
			});

		// disable btn
		setOnSending(true);

		// send to api
		register(email, password, name);
	};

	const togglePasswordVisibility = () => {
		let newType = passwordType === "password" ? "text" : "password";
		setPasswordType(newType);
	};

	return (
		<div className="container-fluid">
			<div className="row d-flex justify-content-center align-items-center">
				{/* FORM HALF */}
				<div className="col-md-6 vh-100 bg-white">
					<div className="container w-75">
						<h1 className="text-center fw-bold text-neonblue mt-5 mb-3">
							Sign up
						</h1>

						<p className="fw-bold text-center">
							Get into the world of Loan and enjoy variety of options.
						</p>

						<div className="d-flex flex-row align-items-center justify-content-center">
							<a
								href="#"
								role="button"
								className="btn btn-light btn-floating mx-2"
								onClick={(e) => {
									e.preventDefault();
									signInWithGoogle();
								}}
							>
								<img src={googleLogo} alt="Google logo"></img>
							</a>

							<a
								href="#"
								role="button"
								className="btn btn-light btn-floating mx-2"
								onClick={(e) => {
									e.preventDefault();
									signInWithFacebook();
								}}
							>
								<img src={facebookLogo} alt="Facebook logo" />
							</a>

							<a
								href="#"
								role="button"
								className="btn btn-light btn-floating mx-2"
								onClick={(e) => {
									e.preventDefault();
									signInWithTwitter();
								}}
							>
								<img src={twitterLogo} alt="Twitter logo" />
							</a>
						</div>

						<form onSubmit={(form) => onSubmitForm(form)}>
							<div className="divider d-flex align-items-center my-4">
								<p
									className="text-center mx-3 mb-0"
									style={{ fontSize: "small" }}
								>
									Or Sign up via existing E-mail
								</p>
							</div>

							{/* Name input */}
							<div className="form-outline mb-4">
								<label className="form-label" htmlFor="form3Example5">
									<span>Name</span>
									{nameError && nameError.length > 0 ? (
										<span className="label-error text-danger">{nameError}</span>
									) : (
										""
									)}
								</label>
								<input
									type="text"
									id="form3Example5"
									className="form-control"
									placeholder="Your name..."
									onChange={(ev) => setName(ev.target.value)}
									defaultValue={name}
								/>
							</div>

							{/* Email input */}
							<div className="form-outline mb-4">
								<label className="form-label" htmlFor="form3Example3">
									<span>Email</span>
									{emailError && emailError.length > 0 ? (
										<span className="label-error text-danger">
											{emailError}
										</span>
									) : (
										""
									)}
								</label>
								<input
									type="email"
									id="form3Example3"
									className="form-control"
									placeholder="mail@website.com"
									onChange={(ev) => setEmail(ev.target.value)}
									defaultValue={email}
								/>
							</div>

							{/* Password input */}
							<div className="form-outline mb-3">
								<label className="form-label" htmlFor="form3Example4">
									<span>Password</span>
									{passwordError && passwordError.length > 0 ? (
										<span className="label-error text-danger">
											{passwordError}
										</span>
									) : (
										""
									)}
								</label>
								<div className="password-visibility-container">
									<input
										type={passwordType}
										id="form3Example4"
										className="form-control"
										placeholder="Min. 8 characters"
										onChange={(ev) => setPassword(ev.target.value)}
										defaultValue={password}
									/>
									<span
										onClick={() => togglePasswordVisibility()}
										className="password-visibility"
									>
										{passwordType === "password" ? "Show" : "Hide"}
									</span>
								</div>
							</div>

							<div className="d-flex justify-content-between align-items-center">
								{/* Checkbox */}
								<div className="form-check mb-0">
									<input
										className="form-check-input me-2"
										type="checkbox"
										id="form2Example3"
										defaultChecked={remember}
										onChange={() => setRemember(!remember)}
									/>
									<label className="form-check-label" htmlFor="form2Example3">
										Remember me
									</label>
								</div>
								{/* <a href="#!" className="text-neonblue text-decoration-none">
                  Forgot password?
                </a> */}
							</div>
							<div className="text-center text-lg-start mt-4 pt-2">
								<div className="d-grid">
									<button
										type="submit"
										className="btn btn-neonblue btn-lg"
										disabled={onSending}
									>
										{!onSending ? "Sign Up" : "Loading, please wait..."}
									</button>
								</div>

								<p className="small fw-bold text-center mt-2 pt-1 mb-0">
									Have an account?{" "}
									<Link
										to="/login"
										className="text-primary text-decoration-none"
									>
										Log in
									</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
				{/* END FORM HALF */}

				{/* HERO HALF */}
				<div
					className="col-md-6 vh-100"
					style={{
						background: `#1155F7 url(${finvestFLogo}) no-repeat`,
					}}
				>
					<div className="d-flex align-items-center vh-100">
						{/* CONTENT */}
						<div className="container">
							<div className="row">
								<div className="col text-center text-white mx-auto vh-100">
									<img
										src={finvestWhiteLogo}
										className="mx-auto d-block mt-5"
										alt="Finvest kit logo"
										style={{ height: "11%" }}
									></img>

									<img
										src={loginImage}
										className="mx-auto d-block my-4"
										alt="Hero image"
										style={{ height: "43%" }}
									></img>

									<h2 className="mx-auto my-4 w-50">
										Get desired loan at your confort
									</h2>

									<p
										className="mx-auto"
										style={{ fontSize: "small", width: "65%" }}
									>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s
									</p>
								</div>
							</div>
						</div>
						{/* END CONTENT */}
					</div>
				</div>
				{/* END HERO HALF */}
			</div>
		</div>
	);
}
