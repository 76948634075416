export default function RangeSlider(props) {
  return (
    <div className="slidecontainer mt-3">
      <input
        type="range"
        min={1}
        max={100}
        value={props.value}
        className="slider"
        id="myRange"
				onChange={props.onChange}
      />
    </div>
  );
}
