import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="container">
      <div className="row text-center" style={{ marginTop: "7%" }}>
        <div className="col">
          <h1 style={{ fontSize: "6rem", color: "#555" }}>404</h1>
          <p style={{ fontSize: "1.5rem", color: "#6c757d" }}>
            Oops! Page not found.
          </p>
          <p style={{ fontSize: "1.5rem", color: "#6c757d" }}>
            The page you are looking for might have been removed, is temporarily
            unavailable or does not exist.
          </p>

          <Link to="/" className="btn btn-primary mt-5">
            Go Back Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
