export default function AddonNumberInput(props) {
  return (
    <div className="input-group my-2">
      <span className="input-group-text border-bluishgrey">
        {props.addontext}
      </span>

      <input
        type="number"
        className="form-control border-bluishgrey"
        id={props.id}
        name={props.name}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        aria-label={props.arialabel}
				onChange={props.onChange}
				value={props.value}
      />
    </div>
  );
}

/* <>
<AddonNumberInput
	id={""}
	name={""}
	min={"0"}
	max={""}
	placeholder={""}
	addontext={"$"}
	arialabel={""}
/>
;
</>; */