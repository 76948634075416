import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { AppContext } from "../AppContext";
import { useContext } from "react";



ChartJS.register(ArcElement, Tooltip, Legend);

export default function HomePieChart() {
	/* ----------------------------------------------------------------------------------------
	States & variables
	-------------------------------------------------------------------------------------------*/	
	// context
  const { loanResult } = useContext(AppContext);

	
	// ---------------------- CHARTS ------------------------------
	// options
	let options = { responsive: true, maintainAspectRatio: false };

	// data
	let data = {
		labels: ["Principal Loan Amount", "Total Interest"],
		datasets: [
			{
				label: "%",
				data: [
					(Number(loanResult.totalPrinciplePlusInterest) * 100 / (Number(loanResult.totalPrinciplePlusInterest) + Number(loanResult.totalInterest))).toFixed(0), 
					(Number(loanResult.totalInterest) * 100 / (Number(loanResult.totalPrinciplePlusInterest) + Number(loanResult.totalInterest))).toFixed(0)
				],
				backgroundColor: ["#1155F7", "#91C6FD"],
				borderColor: ["#1155F7", "#91C6FD"],
				borderWidth: 1,
			},
		],
	};


	return <Pie data={data} options={options} />;
}
